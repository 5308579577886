import { DashboardData } from '../types/dashboard';

export const DEFAULT_DASHBOARD_DATA: DashboardData = {
  dashboardId: -1,
  dashboardGroupId: -1,
  dashboardName: '',
  preset: false,
  shared: false,
  sharing: false,
  startScreen: false,
  createUser: false,
  featuredReport: false,
  customReport: false,
  dashboardOption: {
    showTimePeriod: true,
  },
  iconId: '',
  creator: {
    name: '',
    activeId: '',
    roleId: -1,
    profileImage: '',
  },
};
