import { computed, getCurrentInstance, ref, watch } from 'vue';
import { CustomColumn } from '@/common/utils/types';
import { getInitPeriodInfo } from '@/common/components/molecules/timePeriodIndicator/timePeriodIndicator.setup';
import { TimePeriodInfo } from '@/common/components/molecules/timePeriodIndicator/timePeriodIndicator.types';
import { useSlideDetailApi } from '@/alert/components/alertDetail/alertDetail.use';
import {
  clearAlertRuleControllerAxios,
  getCurrentAlertRuleControllerAxios,
} from '@/openapi/alert/api/alert-rule-controller-api';
import { AlertCurrentItem } from '@/openapi/alert/model';
import {
  AlertDetailEmit,
  AlertDetailTabProps,
} from '@/alert/components/alertDetail/alertDetail.types';
import {
  confirmMsg,
  convertMsToSec,
  showErrorMsg,
  showSuccessMsg,
} from '@/common/utils/commonUtils';
import { useInternational } from '@/common/locale';
import {
  clearSystemRuleControllerAxios,
  getCurrentSystemRuleControllerAxios,
} from '@/openapi/systemAlert/api/system-rule-controller-api';
import { TARGET_TYPE, useAlertTagsAndTargets } from '@/alert/utils/tagsAndTargets.uses';
import { getSlideDetailStore } from '@/common/stores/slide-detail';
import { CHECK_BY_COMBINATION, CHECK_BY_TARGET, NOTI_SENT_INFO } from '@/alert/utils/define';
import { FRAME_NAMES } from '@/common/define/apiTrace.define';
import { AlertRowFormatter, useEventTypeValue } from '@/alert/components/alertGrid/alertGrid.uses';
import { isArray } from 'lodash-es';
import { makeRowToObj } from '@/common/utils/gridUtils';
import { ROLE_PERMISSION_KEY } from '@/common/define/rolePermission.define';
import { useRolePermission } from '@/common/permission/permission.utils';
import { useAlertFormatter } from '@/alert/utils/utils';
import { storeToRefs } from 'pinia';
import { useAlertDetailStore } from '@/alert/stores/alert-detail';

export interface Emit extends AlertDetailEmit {
  (e: 'clear'): void;
}

const BASE_GRID_COLUMNS: CustomColumn[] = [
  {
    caption: 'Alert Name',
    field: 'alertName',
    type: 'string',
    searchable: true,
  },
  {
    caption: 'Last Alert',
    field: 'lastAlert',
    type: 'string',
    rendererType: 'label',
    searchable: true,
  },
  {
    caption: 'Target',
    field: 'targets',
    type: 'string',
    rendererType: 'chip-cell',
    sortable: false,
    searchable: true,
  },
  {
    caption: 'First Triggered',
    field: 'firstTriggered',
    type: 'string',
    searchable: true,
  },
  {
    caption: 'Last Triggered',
    field: 'lastTriggered',
    type: 'string',
    searchable: true,
  },
  {
    caption: 'Duration (sec)',
    field: 'duration',
    type: 'number',
    searchable: true,
  },
  {
    caption: 'Alert Value',
    field: 'value',
    type: 'string',
    sortable: false,
    searchable: true,
  },
  // {
  //   caption: 'Description',
  //   field: 'description',
  //   type: 'string',
  // },
  {
    caption: 'Notification Status',
    field: 'notiStatus',
    type: 'string',
    sortable: false,
    searchable: true,
  },
  {
    caption: 'Rule ID',
    field: 'ruleId',
    type: 'string',
    hide: true,
  },
  {
    caption: 'Event Type',
    field: 'eventType',
    type: 'string',
    hide: true,
  },
];

export const setup = (props: AlertDetailTabProps, emit: Emit) => {
  const { t } = useInternational();
  const ctx = getCurrentInstance()!.appContext.config.globalProperties;
  const slideDetailStore = getSlideDetailStore();
  const { callApi, abortApi } = useSlideDetailApi();
  const { isPermissionDenied } = useRolePermission();

  const { selectedRuleInfo: ruleInfo } = storeToRefs(useAlertDetailStore());

  const periodInfo = ref<TimePeriodInfo>({
    ...getInitPeriodInfo(),
    isPaused: true,
  });
  const ruleCriteria = computed(() => ruleInfo.value.ruleCriteria || CHECK_BY_TARGET);
  const gridColumns = computed<CustomColumn[]>(() => {
    if (ruleCriteria.value === CHECK_BY_COMBINATION) {
      return [
        {
          caption: 'Rule Name',
          field: 'ruleName',
          type: 'string',
        },
        ...BASE_GRID_COLUMNS,
      ];
    }

    return BASE_GRID_COLUMNS;
  });

  const gridData = ref<any[]>([]);

  const checkedRows = ref<any[]>([]);
  const checkedObjRows = computed(() =>
    isArray(checkedRows.value?.[0])
      ? checkedRows.value.map((row) => makeRowToObj(row, gridColumns.value))
      : checkedRows.value,
  );

  const searchWord = ref('');

  const { makeAlertRows, makeAlertTreeRows } = useAlertTagsAndTargets();
  const alertFormatter = useAlertFormatter();

  const makeRowsController = {
    [CHECK_BY_TARGET]: makeAlertRows,
    [CHECK_BY_COMBINATION]: makeAlertTreeRows,
  };

  const setGridData = (rows: AlertCurrentItem[], formatter: AlertRowFormatter = {}) => {
    searchWord.value = '';
    checkedRows.value = [];

    if (!rows?.length) {
      gridData.value = [];
    }

    const makeRows = makeRowsController[ruleCriteria.value];
    gridData.value = makeRows({
      data: rows,
      columns: gridColumns.value,
      targetFieldName: 'target',
      targetType: TARGET_TYPE.TARGETS,
      formatter: {
        notiSent: (v) => NOTI_SENT_INFO[v] ?? '-',
        duration: (v) => convertMsToSec(v),
        ...(formatter || {}),
      },
      hasNullRow: true,
    });
  };

  const dataSettingController = {
    user: async () => {
      if (!ruleInfo.value.ruleId) {
        setGridData([]);
        return;
      }
      const { data, error } = await callApi({
        fn: getCurrentAlertRuleControllerAxios,
        params: {
          alertRuleId: ruleInfo.value.ruleId,
        },
        frameName: FRAME_NAMES.ALERT_DETAIL.CURRENT,
      });
      if (!error) {
        setGridData(data || [], {
          alertName: (v) => {
            if (ruleInfo.value.subType === 'metric') {
              return alertFormatter.alertName(v);
            }
            return v;
          },
        });
      }
      emit('update:isLoading', false);
    },
    system: async () => {
      if (!ruleInfo.value.platform || !ruleInfo.value.event) {
        setGridData([]);
        return;
      }
      const { data, error } = await callApi({
        fn: getCurrentSystemRuleControllerAxios,
        params: {
          systemKind: ruleInfo.value.platform,
          systemEvent: ruleInfo.value.event,
        },
        frameName: FRAME_NAMES.ALERT_DETAIL.CURRENT,
      });
      if (!error) {
        setGridData(data ?? []);
      }
      emit('update:isLoading', false);
    },
  };
  const onUpdatedIndicator = async (info: TimePeriodInfo) => {
    periodInfo.value = info;
    if (!props.isShow || !ruleInfo.value?.type || info.isPaused) {
      return;
    }

    await dataSettingController[ruleInfo.value.type]?.();
  };

  const clearController = {
    user: async (rowData: any[]) => {
      if (!rowData?.length) {
        return;
      }
      const clearRequests = rowData.map((item) => {
        const targets =
          ruleInfo.value.ruleCriteria === CHECK_BY_COMBINATION
            ? item.children?.map((child) => child.targets || []).flat()
            : item.targets;
        const targetsRequest = targets.map((v) => ({
          category: v.name.split(':')?.[0],
          targetId: v.id,
        }));
        return {
          alertRuleId: ruleInfo.value.ruleId,
          targetsRequest,
        };
      });
      await clearAlertRuleControllerAxios({
        clearRequests,
        frameName: FRAME_NAMES.ALERT_DETAIL.CURRENT_CLEAR,
      });
    },
    system: async (rowData: any[]) => {
      const clearRequests = rowData.map((item) => {
        const targetsRequest = item.targets?.map((v) => ({
          category: v.name.split(':')?.[0],
          targetId: v.id,
        }));
        return {
          ruleId: item.ruleId,
          systemEvent: ruleInfo.value.event,
          systemKind: ruleInfo.value.platform,
          targetsRequest,
        };
      });
      await clearSystemRuleControllerAxios({
        request: { clearRequests },
        frameName: FRAME_NAMES.ALERT_DETAIL.CURRENT_CLEAR,
      });
    },
  };
  const onClearAlert = () => {
    const isDenied = isPermissionDenied({
      type: 'action',
      rolePermissionKey: ROLE_PERMISSION_KEY.MONITORING.MONITORING_ALERT_LIST_CLEAR,
    });
    if (isDenied) {
      return;
    }

    const { type } = ruleInfo.value;
    confirmMsg(ctx, {
      msgStr: t('MESSAGE.DELETE'),
      okCallback: async () => {
        try {
          await clearController[type](checkedObjRows.value);
          emit('clear');
          showSuccessMsg(ctx, t('MESSAGE.ALERT_DELETED'));
          await dataSettingController[type]?.();
        } catch (e: any) {
          const { status } = e?.response ?? {};
          if (status !== 406) {
            showErrorMsg(ctx, t('MESSAGE.ALERT_DELETED_FAIL'));
          }
        }
      },
    });
  };

  const { isShowMessageWindow, eventAlertInfo, onClickValueCell } = useEventTypeValue();
  const onClickCell = async ({ field, value, row }) => {
    if (field === 'value') {
      await onClickValueCell({ value, row, ruleId: ruleInfo.value.ruleId });
    }
  };

  let prevIsPaused = false;
  watch(isShowMessageWindow, (val) => {
    if (val) {
      prevIsPaused = periodInfo.value.isPaused;
      periodInfo.value.isPaused = true;
    } else {
      periodInfo.value.isPaused = prevIsPaused;
    }
  });

  watch(
    [() => props.isShow, ruleInfo],
    ([isShow]) => {
      if (isShow) {
        emit('update:isLoading', true);
        periodInfo.value.isPaused = false;
      } else {
        periodInfo.value.isPaused = true;
        setGridData([]);
        abortApi();
      }
    },
    { immediate: true },
  );

  return {
    ruleCriteria,
    periodInfo,
    gridColumns,
    gridData,
    checkedRows,
    searchWord,
    onUpdatedIndicator,
    onClearAlert,

    isShowMessageWindow,
    eventAlertInfo,
    onClickCell,
    t,
  };
};
