/* tslint:disable */
/* eslint-disable */
import {
  axiosInstance,
  timeout5SecAxiosInstance,
  PromiseAxiosResponse,
} from '@/worker/commands/config/apiInstance';
import { ResponseType } from 'axios';
import {
  DashboardShareGroupWithUserResponse,
  DashboardStartScreenRequest,
  IntegrateDashboardShareDeleteV8Request,
  IntegrateDashboardSharePatchV8Request,
  IntegrateDashboardSharedDeleteV8Request,
  IntegrateDashboardV8Response,
  SuccessResponse,
} from '../model';
/* tslint:enable */
/* eslint-enable */

const getAxiosInstanceWrapper = ({
  httpMethod,
  localVarPath,
  data = undefined,
  config = {},
  isTimeout = false,
}: {
  httpMethod: string;
  localVarPath: string;
  data?: any;
  config?: any;
  isTimeout?: boolean;
}) => {
/* eslint-disable */
  const instance = isTimeout ? timeout5SecAxiosInstance : axiosInstance;
  if (['request'].includes(httpMethod)) {
    // @ts-ignore
    return instance[httpMethod](config);
  }
  if (['get', 'delete', 'head', 'options'].includes(httpMethod)) {
    // @ts-ignore
    return instance[httpMethod](localVarPath, config);
  }
  // @ts-ignore
  return instance[httpMethod](localVarPath, data, config);
  /* eslint-enable */
};

const getBoardsIntegratedDashboardV8ControllerAxios = ({
  column,
  dashboardId,
  includeImage,
  pageNum,
  pageSize,
  sortType,
  userId,
  signal,
  frameName,
  isTimeout,
  isPolling,
  responseType,
}: {
  column?: string;
  dashboardId?: number;
  includeImage?: boolean;
  pageNum?: number;
  pageSize?: number;
  sortType?: 'ASC' | 'DESC';
  userId?: number;
  signal?: AbortController['signal'];
  frameName?: string;
  isTimeout?: boolean;
  isPolling?: boolean;
  responseType?: ResponseType;
}): PromiseAxiosResponse<IntegrateDashboardV8Response> => {
  const localVarPath = '/api/v8/dashboards/dashboard';
  const httpMethod = 'get';

  const data: any = {};
  const config: any = {
    params: {
      column,
      dashboardId,
      includeImage,
      pageNum,
      pageSize,
      sortType,
      userId,
      frameName,
      isPolling,
    },
    signal,
    responseType: responseType ?? 'json',
  };
  return getAxiosInstanceWrapper({
    httpMethod,
    localVarPath,
    data,
    config,
    isTimeout,
  });
};

const getGroupWithUsersIntegratedDashboardV8ControllerAxios = ({
  dashboardId,
  signal,
  frameName,
  isTimeout,
  isPolling,
  responseType,
}: {
  dashboardId?: number;
  signal?: AbortController['signal'];
  frameName?: string;
  isTimeout?: boolean;
  isPolling?: boolean;
  responseType?: ResponseType;
}): PromiseAxiosResponse<DashboardShareGroupWithUserResponse> => {
  const localVarPath = '/api/v8/dashboards/groups-with-users';
  const httpMethod = 'get';

  const data: any = {};
  const config: any = {
    params: {
      dashboardId,
      frameName,
      isPolling,
    },
    signal,
    responseType: responseType ?? 'json',
  };
  return getAxiosInstanceWrapper({
    httpMethod,
    localVarPath,
    data,
    config,
    isTimeout,
  });
};

const setStartScreenIntegratedDashboardV8ControllerAxios = ({
  request,
  signal,
  frameName,
  isTimeout,
  isPolling,
  responseType,
}: {
  request: DashboardStartScreenRequest;
  signal?: AbortController['signal'];
  frameName?: string;
  isTimeout?: boolean;
  isPolling?: boolean;
  responseType?: ResponseType;
}): PromiseAxiosResponse<SuccessResponse> => {
  const localVarPath = '/api/v8/dashboards/dashboard/start-screen';
  const httpMethod = 'patch';

  const data: any = request;
  const config: any = {
    params: {
      frameName,
      isPolling,
    },
    signal,
    responseType: responseType ?? 'json',
  };
  return getAxiosInstanceWrapper({
    httpMethod,
    localVarPath,
    data,
    config,
    isTimeout,
  });
};

const patchBoardShareIntegratedDashboardV8ControllerAxios = ({
  request,
  signal,
  frameName,
  isTimeout,
  isPolling,
  responseType,
}: {
  request: IntegrateDashboardSharePatchV8Request;
  signal?: AbortController['signal'];
  frameName?: string;
  isTimeout?: boolean;
  isPolling?: boolean;
  responseType?: ResponseType;
}): PromiseAxiosResponse<SuccessResponse> => {
  const localVarPath = '/api/v8/dashboards/dashboard/user-share';
  const httpMethod = 'patch';

  const data: any = request;
  const config: any = {
    params: {
      frameName,
      isPolling,
    },
    signal,
    responseType: responseType ?? 'json',
  };
  return getAxiosInstanceWrapper({
    httpMethod,
    localVarPath,
    data,
    config,
    isTimeout,
  });
};

const deleteSharedBoardIntegratedDashboardV8ControllerAxios = ({
  request,
  signal,
  frameName,
  isTimeout,
  isPolling,
  responseType,
}: {
  request: IntegrateDashboardSharedDeleteV8Request;
  signal?: AbortController['signal'];
  frameName?: string;
  isTimeout?: boolean;
  isPolling?: boolean;
  responseType?: ResponseType;
}): PromiseAxiosResponse<SuccessResponse> => {
  const localVarPath = '/api/v8/dashboards/dashboard/user-shared/unshare';
  const httpMethod = 'patch';

  const data: any = request;
  const config: any = {
    params: {
      frameName,
      isPolling,
    },
    signal,
    responseType: responseType ?? 'json',
  };
  return getAxiosInstanceWrapper({
    httpMethod,
    localVarPath,
    data,
    config,
    isTimeout,
  });
};

const deleteSharingBoardIntegratedDashboardV8ControllerAxios = ({
  dashboardId,
  request,
  signal,
  frameName,
  isTimeout,
  isPolling,
  responseType,
}: {
  dashboardId: number;
  request: IntegrateDashboardShareDeleteV8Request;
  signal?: AbortController['signal'];
  frameName?: string;
  isTimeout?: boolean;
  isPolling?: boolean;
  responseType?: ResponseType;
}): PromiseAxiosResponse<SuccessResponse> => {
  const localVarPath = '/api/v8/dashboards/dashboard/{dashboardId}/user-sharing/unsharing'.replace(
    `{${'dashboardId'}}`,
    encodeURIComponent(String(dashboardId)),
  );
  const httpMethod = 'patch';

  const data: any = request;
  const config: any = {
    params: {
      frameName,
      isPolling,
    },
    signal,
    responseType: responseType ?? 'json',
  };
  return getAxiosInstanceWrapper({
    httpMethod,
    localVarPath,
    data,
    config,
    isTimeout,
  });
};

export {
  getBoardsIntegratedDashboardV8ControllerAxios,
  getGroupWithUsersIntegratedDashboardV8ControllerAxios,
  setStartScreenIntegratedDashboardV8ControllerAxios,
  patchBoardShareIntegratedDashboardV8ControllerAxios,
  deleteSharedBoardIntegratedDashboardV8ControllerAxios,
  deleteSharingBoardIntegratedDashboardV8ControllerAxios,
};
