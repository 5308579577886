import {
  BY_STAT_CHART_TYPES,
  BY_TARGET_CHART_TYPES,
  MAX_LENGTH_FOR_DASHBOARD,
} from '@/dashboard/utils/define';
import {
  ByStatChartTypes,
  ByTargetChartTypes,
  DisplayStyleType,
  GridLayoutItem,
  LENGTH_RULE_KEY,
  ViewPackListItem,
  WidgetChartData,
  WidgetChartType,
  WidgetCreateType,
  WidgetExtraData,
  WidgetTarget,
  WidgetTimePeriod,
  WidgetTimePeriodList,
} from '@/dashboard/utils/types';
import { camelCase, isEqual } from 'lodash-es';
import { TargetTagType, WidgetChartDataStatusInfo } from '@/common/utils/types';
import { COLOR_PALETTE_LIST, DEFAULT_ORDERED_COLOR_KEY_LIST } from '@/dashboard/utils/color.define';
import { confirmMsg } from '@/common/utils/commonUtils';
import { i18n } from '@/common/locale';
import type { TagsRequest, TagsRequestTagType } from '@/openapi/data/model';
import type { ArgParam } from '@/dashboard/components/widgets/useCustomStat';
import { getExtraColorListByTheme } from '@/common/utils/chartUtils';
import { FilterVariableOperationType } from '../stores/global-filter';
import { ReferenceInfo } from '../components/widgetSettingsWindow/referenceSettingOption/referenceSettingOption.setup';
import { ChartOptions, ChartType } from '../components/widgets/widgets.types';
import type { DashboardRowData } from '../views/dashboardListView/dashboardListView.types';
import { RequestTagWithWidgetInfo } from '../stores/dashboard-targets';

export const isJsonType = (value: string): boolean => {
  try {
    const json = JSON.parse(value);
    return typeof json === 'object';
  } catch (e) {
    return false;
  }
};

export const convertSnakeToCamel = (str: string): string => camelCase(str.toLowerCase());

export const convertCamelToCapitalStart = (str: string): string =>
  str.charAt(0).toUpperCase() + str.slice(1);

export const getWidgetCompName = (chartType: string, displayStyle: string): string => {
  const chartTypeCapital = convertCamelToCapitalStart(convertSnakeToCamel(chartType));
  const displayStyleCapital = convertCamelToCapitalStart(convertSnakeToCamel(displayStyle));
  return `${chartTypeCapital}${displayStyleCapital}Widget`;
};

export const getStringByteLength = (s: string) => {
  const textEncoder = new TextEncoder();
  return textEncoder.encode(s).length;
};

export const checkValidDashboardName = (dashboardName: string): string => {
  let errorMsg = '';

  // 이모지 및 돔태그 형태에 대한 정규표현식
  const dashboardNameRule = /[\ud800-\udbff][\udc00-\udfff]|<[^>]+>/;
  if (!dashboardName.trim()) {
    errorMsg = i18n.global.t('MESSAGE.ENTER_DASHBOARD_NAME');
  } else if (dashboardNameRule.test(dashboardName)) {
    errorMsg = 'Only letters, numbers, and special characters are allowed.';
  }

  return errorMsg;
};

export const checkValidViewPackName = (
  name: string,
  id: number,
  viewPackList: ViewPackListItem[],
): string => {
  let errorMsg = '';

  const viewPackNameRule = /([^a-zA-Z0-9\s-_ㄱ-ㅎㅏ-ㅣ가-힣])/;
  if (!name.trim()) {
    errorMsg = 'Please enter a View Pack name.';
  } else if (viewPackNameRule.test(name)) {
    errorMsg = 'Only letters, numbers, -, _ are allowed.';
  } else {
    const isDuplicatedName = viewPackList.find(
      (viewPack) => +viewPack.id !== id && viewPack.name.toLowerCase() === name.toLowerCase(),
    );

    if (isDuplicatedName) {
      errorMsg = 'It has the same View Pack name.';
    }
  }

  return errorMsg;
};

export const adjustByMaxLength = (targetString: string, ruleKey: LENGTH_RULE_KEY): string => {
  let result = targetString;

  const maxLength = MAX_LENGTH_FOR_DASHBOARD[ruleKey];

  if (result?.length) {
    while (getStringByteLength(result) > maxLength) {
      result = targetString.slice(0, result.length - 1);
    }
  }

  return result;
};

export const convertToImageURL = (
  imageSrc: string | undefined,
  type: 'dashboard' | 'viewPack' | 'widget',
): string => {
  try {
    if (!imageSrc) {
      throw new Error('image src not exist');
    }

    if (imageSrc.startsWith('data:image/')) {
      return imageSrc;
    }

    return new URL(imageSrc, import.meta.url)?.href;
  } catch {
    return new URL(`/src/common/assets/widgets/${type}-default.png`, import.meta.url)?.href;
  }
};

export const isWidgetTimePeriod = (timePeriod: any): timePeriod is WidgetTimePeriod => {
  return WidgetTimePeriodList.includes(timePeriod as WidgetTimePeriod);
};

export const isByTargetChartType = (
  chartType: WidgetChartType,
): chartType is ByTargetChartTypes => {
  return BY_TARGET_CHART_TYPES.includes(chartType as ByTargetChartTypes);
};

export const isByStatChartType = (chartTypeInfo: {
  chartType: string;
  displayStyle: string;
}): chartTypeInfo is ByStatChartTypes => {
  return BY_STAT_CHART_TYPES.some((typeInfo) => isEqual(typeInfo, chartTypeInfo));
};

export const getDefaultSeriesColors = (): string[] => {
  const mergedColorPaletteList = COLOR_PALETTE_LIST.reduce((acc, obj) => {
    Object.keys(obj).forEach((key) => {
      acc[key] = obj[key];
    });
    return acc;
  }, {});

  const defaultColors =
    DEFAULT_ORDERED_COLOR_KEY_LIST.map((key) => {
      const colors: { background: string; border: string }[] = mergedColorPaletteList[key];
      return colors[colors?.length - 1].background;
    }) ?? [];

  return defaultColors.concat(getExtraColorListByTheme());
};

export const getDefaultSeriesColor = (index: number): string => {
  const colors = getDefaultSeriesColors();

  return colors[index % colors.length];
};

export const convertChartTypeToWidgetChartType = (
  chartType: ChartType,
): [WidgetChartType, DisplayStyleType?] => {
  const map: {
    [key in ChartType]: [WidgetChartType, DisplayStyleType?];
  } = {
    timeseries: ['TIME_SERIES'],
    oneDayCompare: ['ONE_DAY_COMPARE'],
    exclusive: ['EXCLUSIVE'],
    scoreboard: ['SCOREBOARD'],
    'scoreboard-style2': ['SCOREBOARD', 'STYLE_2'],
    stackedProportionBar: ['STACKED_PROPORTION_BAR'],
    gauge: ['GAUGE'],
    equalizer: ['EQUALIZER'],
    actionView: ['ACTION_VIEW'],
    table: ['TABLE'],
    statusSummary: ['STATUS_SUMMARY'],
    pie: ['PIE'],
    serviceCard: ['SERVICE_CARD'],
    topList: ['TOP_LIST'],
    statusHexa: ['STATUS'],
    treeGrid: ['TREE_GRID'],
    scatter: ['SCATTER'],
    topology: ['TOPOLOGY'],
  };
  return map[chartType];
};

export const getChartDataStatusById = (
  chartDataStatusInfoList: WidgetChartDataStatusInfo[],
  chartDataId: string,
): WidgetChartDataStatusInfo => {
  const chartDataStatus = chartDataStatusInfoList.find(
    ({ chartDataId: statusChartDataId }) => statusChartDataId === chartDataId,
  );
  return chartDataStatus ?? { status: 'success', chartDataId };
};

export const initDefaultCustomColors = <Item>(
  list: Item[],
  key?: string,
): (Item & { color: string | Record<string, string> })[] => {
  return list.map((item, index) => ({
    ...item,
    color: key
      ? {
          [key]: getDefaultSeriesColor(index),
        }
      : getDefaultSeriesColor(index),
  }));
};

export const sortDashboardList =
  (
    group: string,
    indexInfo: {
      favorite: number;
      startScreen: number;
      groupId: number;
      name: number;
      shared: number;
      sharing: number;
      createUser: number;
      lastModified: number;
      featuredDashboard: number;
      featuredReport: number;
      customReport: number;
      creator: number;
    },
  ) =>
  (a: DashboardRowData, b: DashboardRowData): number => {
    type DashboardSortData = {
      favorite: number;
      startScreen: number;
      groupId: string;
      name: string;
      lastModified: string;
      shared: number;
      sharing: number;
      featured: number;
      customReport: number;
      creator: {
        name: string;
        activeId: string;
        profileImage: string;
      };
    };

    const parseRowData = (rowData: DashboardRowData): DashboardSortData => {
      return {
        favorite: +rowData[indexInfo.favorite],
        startScreen: rowData[indexInfo.startScreen] ? 2 : 0,
        groupId: String(rowData[indexInfo.groupId]),
        name: `${rowData[indexInfo.name]}`,
        lastModified: `${rowData[indexInfo.lastModified]}`,
        shared: rowData[indexInfo.shared] ? 2 : 0,
        sharing: rowData[indexInfo.sharing] ? 2 : 0,
        featured: rowData[indexInfo.featuredDashboard] || rowData[indexInfo.featuredReport] ? 1 : 0,
        customReport: rowData[indexInfo.customReport] ? 1 : 0,
        creator: {
          name: (rowData[indexInfo.creator] as DashboardRowData[16]).name,
          activeId: (rowData[indexInfo.creator] as DashboardRowData[16]).activeId,
          profileImage: (rowData[indexInfo.creator] as DashboardRowData[16]).profileImage,
        },
      };
    };

    const dataA: DashboardSortData = parseRowData(a);
    const dataB: DashboardSortData = parseRowData(b);

    const priorityOrder: Array<keyof DashboardSortData> = [
      'startScreen',
      'favorite',
      'featured',
      'sharing',
      'shared',
      'customReport',
    ];

    let comparisonResult: number | undefined;

    priorityOrder.some((key) => {
      const valueA = dataA[key] as number;
      const valueB = dataB[key] as number;
      if (valueA !== valueB) {
        comparisonResult = valueB - valueA;
        return true;
      }
      return false;
    });

    if (comparisonResult !== undefined) {
      return comparisonResult;
    }

    return dataA.name.localeCompare(dataB.name);
  };

export const uploadJsonFile = async (e, ctx): Promise<string[]> => {
  e.stopPropagation();
  e.preventDefault();

  const files: FileList = e.target ? e.target.files : null;

  if (!files.length) {
    return [];
  }

  const isAllJsonExtension = Array.from(files).every(
    (file) => file.name.split('.')[1]?.toLowerCase() === 'json',
  );

  if (!isAllJsonExtension) {
    confirmMsg(ctx, {
      msgStr: 'Only json files are allowed.',
      okCallback: () => {},
      useHTML: false,
      showClose: false,
      showCancelBtn: false,
    });
    return [];
  }

  const readFilePromise = (file: File): Promise<string> => {
    return new Promise((resolve) => {
      const reader = new FileReader();

      reader.onload = (event) => {
        const jsonData = event.target?.result as string;
        resolve(jsonData);
      };

      reader.readAsText(file);
    });
  };

  const readFiles = Array.from(files).map((file) => readFilePromise(file));
  return Promise.all(readFiles).then((jsonFiles) => jsonFiles);
};

export const encodeArgParam = (
  value: string | boolean | number | (string | number)[] | null | undefined,
): string | undefined => {
  if (value == null) return undefined;
  switch (typeof value) {
    case 'string':
      return `'${value}'`;
    case 'number':
    case 'boolean':
      return `${value}`;
    case 'object':
      if (Array.isArray(value)) {
        return value.map(encodeArgParam).join(', ');
      }
      break;
    default:
      return `${value}`;
  }
  return `${value}`;
};

export const decodeArgParam = (value: string | undefined): string | undefined => {
  if (value == null) return undefined;
  return value.replaceAll("'", '').trim();
};

export const disabledUseCustomStat = (chartType: WidgetChartType) => {
  return ['ONE_DAY_COMPARE', 'ACTION_VIEW', 'GAUGE'].includes(chartType);
};

export const filterChartDataByCreateType = (
  chartType: WidgetChartType,
  createType: WidgetCreateType,
  statCategory: string,
) => {
  const isChartCanUseCustomStat = !disabledUseCustomStat(chartType);
  return (
    (isChartCanUseCustomStat && createType === 'reference') ||
    ((!isChartCanUseCustomStat || createType === 'base') && statCategory !== 'custom')
  );
};

export const isStatClear = (value: WidgetChartData) => {
  return !value.dataId && !value.category;
};

export const isCustomStatId = (statId: string) => {
  return statId.startsWith('qm_');
};

export const isChartOptionDisabled = (
  option: 'fill' | 'target' | 'dataType' | 'seriesType' | 'alias',
  statInfo: {
    id: string;
    isReference: boolean;
  },
  defaultDisabled: boolean,
) => {
  if (statInfo.isReference) {
    const isCustomStat = isCustomStatId(statInfo.id);
    switch (option) {
      case 'fill':
        return !!isCustomStat;
      case 'target':
        return true;
      case 'dataType':
        return !!isCustomStat;
      case 'seriesType':
        return false;
      case 'alias':
        return false;
      default:
        return false;
    }
  }
  return defaultDisabled;
};

export const isWidgetReferenceGlobalTimePeriod = <T extends WidgetChartType>(
  chartOption: ChartOptions<T>,
): boolean => {
  return (
    !!chartOption &&
    !!chartOption.referenceInfo &&
    Object.values(chartOption.referenceInfo as ReferenceInfo).some((variableList) =>
      variableList.some(({ value }) => value?.type === 'globalTimePeriod'),
    )
  );
};

export const isGridItemNotUseTimePeriod = ({
  widgetType,
  createType,
  timePeriod,
  chartOption,
}: GridLayoutItem) => {
  return (
    ['TEXT', 'IMAGE', 'GROUP', 'FILTER'].includes(widgetType) ||
    (createType === 'reference' && !isWidgetReferenceGlobalTimePeriod(chartOption)) ||
    timePeriod !== 'globalTime'
  );
};

export const convertToOperationType = (
  tagType: TargetTagType | string,
): FilterVariableOperationType => {
  switch (tagType) {
    case 'attribute':
      return 'AND';
    case 'tag':
    case 'globalVariable':
    case 'serviceGroup':
      return 'OR';
    default:
      throw new Error(`${tagType} 타겟태그타입이 추가되었습니다.`);
  }
};

export const convertWidgetTargetsToExtraData = (
  widgetTargets: WidgetTarget[],
  extraId: string,
): WidgetExtraData => ({
  extraId,
  targets:
    widgetTargets?.map((target) => {
      const targetForm = [
        { id: 'tagKey', value: target.tagKey },
        { id: 'tagType', value: target.tagType },
        { id: 'tagValue', value: target.tagValue },
      ];

      if (target.tagValueId) {
        targetForm.push({ id: 'tagValueId', value: target.tagValueId });
      }

      return targetForm;
    }) ?? [],
});

export const convertExtraDataToWidgetTargets = (
  extraData: WidgetExtraData[] | undefined,
  extraId: string,
): WidgetTarget[] => {
  const extraDataTargets = extraData?.find((data) => data.extraId === extraId)?.targets;
  if (!extraDataTargets?.length || !extraDataTargets[0]?.length) {
    return [];
  }

  return extraDataTargets.map((item) => {
    const targetData: WidgetTarget = {
      tagKey: '',
      tagType: 'tag',
      tagValue: '',
      tagValueId: undefined,
    };

    item?.forEach((tag) => {
      if (tag.id === 'tagKey') {
        targetData.tagKey = tag.value ?? '';
      } else if (tag.id === 'tagType') {
        targetData.tagType = tag.value as TargetTagType;
      } else if (tag.id === 'tagValue') {
        targetData.tagValue = tag.value ?? '';
      } else if (tag.id === 'tagValueId') {
        targetData.tagValueId = tag.value;
      }
    });

    return targetData;
  });
};

export const flattenWidgets = (items: GridLayoutItem[]): GridLayoutItem[] => {
  return items.reduce<GridLayoutItem[]>((acc, item) => {
    acc.push(item);
    if (item.children?.length) {
      acc.push(...flattenWidgets(item.children));
    }
    return acc;
  }, []);
};

export const getAllRequestTags = (targets: WidgetTarget[]): TagsRequest[] => {
  const requestTargets: Map<string, TagsRequest> = new Map();

  targets.forEach(({ tagKey, tagValue, tagType }) => {
    const existingTagRequest = requestTargets.get(tagKey);

    if (existingTagRequest) {
      existingTagRequest.tagValue?.push(tagValue);
    } else {
      requestTargets.set(tagKey, {
        tagKey,
        tagType: tagType as TagsRequestTagType,
        tagValue: [tagValue],
      });
    }
  });

  return Array.from(requestTargets.values());
};

export const getAllRequestTagsRecursively = (
  items: GridLayoutItem[],
  acc: RequestTagWithWidgetInfo[] = [],
): RequestTagWithWidgetInfo[] => {
  items.forEach(({ children, chartData, i, titleOption, chartType }) => {
    if (children?.length) {
      getAllRequestTagsRecursively(children, acc);
    }
    chartData?.forEach(({ targets, category, dataId, value }) => {
      if (value?.isShow === false) {
        return;
      }

      const mergedTargets = getAllRequestTags(targets);
      acc.push({
        dataId,
        chartType,
        widgetUUID: i,
        widgetName: titleOption.titleText,
        category,
        targets: mergedTargets,
      });
    });
  });
  return acc;
};

export const findKeyCustomStat = (stat: ArgParam): string | null => {
  const excludedKeys = ['fromTime', 'toTime'];

  const foundEntry = Object.entries(stat).find(
    ([key, value]) => value.tags === true && !excludedKeys.includes(key),
  );

  return foundEntry ? foundEntry[0] : null;
};
