/* eslint-disable no-shadow */
import { DataStatCategoriesForm, StatItems } from '@/openapi/data/model';
import { defineStore, storeToRefs } from 'pinia';
import { computed, ref, watch } from 'vue';
import {
  getStatsCommonCategoriesDataStatInformationControllerAxios,
  getStatsDataStatInformationControllerAxios,
} from '@/openapi/data/api/data-stat-information-controller-api';
import { store } from '../store';
import { parseStatItems } from '../utils/statInfo.utils';
import { StatCategory, StatInfo } from '../components/organisms/statWindow';
import { useViewModeStore } from './view-mode';
import { VIEW_MODE } from '../define/common.define';

type ParentCategory = string;
type ChildCategory = string;
type StatId = string;

export const getStatInfoList = (
  statInfoListByCategory: Map<ParentCategory, Map<ChildCategory, StatInfo[]>>,
  { parentCategory, childCategory }: StatCategory,
): StatInfo[] | undefined => {
  return statInfoListByCategory.get(parentCategory)?.get(childCategory);
};

export const useStatInfoStore = defineStore('statInfo', () => {
  const { viewMode } = storeToRefs(useViewModeStore());
  const isCategoryListLoading = ref(false);
  const dataStatCategoriesFormList = ref<DataStatCategoriesForm[] | undefined>();

  const isStatsDataLoading = ref(false);
  const statsData = ref<StatItems | undefined>();

  const userInfo = computed(() => store.getters['myInfo/getAccountInfo']);

  const statCategoriesByViewMode = {
    [VIEW_MODE.MAXGAUGE]: [
      'database',
      'mysql',
      'oracle',
      'postgresql',
      'redis',
      'sqlserver',
      'host',
      'custom',
    ],
    [VIEW_MODE.EXEMONE]: [],
  };

  const fetchCategoryList = async () => {
    isCategoryListLoading.value = true;
    try {
      const response = await getStatsCommonCategoriesDataStatInformationControllerAxios({
        categories: statCategoriesByViewMode[viewMode.value],
      });
      const list = response.data.data;
      if (list) {
        dataStatCategoriesFormList.value = list;
      }
    } catch (e) {
      console.log(e);
    } finally {
      isCategoryListLoading.value = false;
    }
  };

  const fetchIndicatorInfo = async () => {
    isStatsDataLoading.value = true;
    try {
      const response = await getStatsDataStatInformationControllerAxios({
        userId: userInfo.value?.userId ?? -1,
      });
      const statItems = response.data.data?.at(0);
      if (statItems) {
        statsData.value = statItems;
      }
    } catch (e) {
      console.log(e);
    } finally {
      isStatsDataLoading.value = false;
    }
  };

  const isLoading = computed(() => isCategoryListLoading.value || isStatsDataLoading.value);

  const fetchData = async () => {
    await fetchCategoryList();
    await fetchIndicatorInfo();
  };

  const statInfo = ref<Map<StatId, StatInfo>>();
  const statInfoByCategoryAndStatId = ref<Map<ChildCategory, Map<StatId, StatInfo>>>();
  const statInfoListByCategory = ref<Map<ParentCategory, Map<ChildCategory, StatInfo[]>>>();

  const getStatInfo = ({
    childCategory,
    statId,
  }: {
    childCategory?: string;
    statId: string;
  }): StatInfo | undefined => {
    if (!childCategory) {
      return statInfo.value?.get(statId);
    }

    return statInfoByCategoryAndStatId.value?.get(childCategory)?.get(statId);
  };

  watch(
    [statsData, dataStatCategoriesFormList],
    ([newStatsData, newDataStatCategoriesFormList]) => {
      if (newStatsData && newDataStatCategoriesFormList) {
        const { infoByStatIdAndCategory, infoByCategoryInfo, info } = parseStatItems(
          newDataStatCategoriesFormList,
          newStatsData,
        );
        statInfoByCategoryAndStatId.value = infoByStatIdAndCategory;
        statInfoListByCategory.value = infoByCategoryInfo;
        statInfo.value = info;
      }
    },
    {
      immediate: true,
    },
  );

  return {
    fetchIndicatorInfo,
    fetchData,
    isLoading,

    getStatInfo,

    statInfoListByCategory,
    statInfoByCategoryAndStatId,
  };
});
