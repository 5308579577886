import { useInternational } from '@/common/locale';
import { WidgetChartDataStatusInfo } from '@/common/utils/types';
import { WidgetModeType, WidgetChartData } from '@/dashboard/utils/types';
import { ComputedRef, getCurrentInstance, watch } from 'vue';
import { showErrorMsg } from '@/common/utils/commonUtils';
import { useStatInfoStore } from '@/common/stores/stat-info';
import { ChartDataType, ChartRawData, ChartType } from '../widgets.types';
import {
  isMetricRawData,
  isCurrentRawData,
  isTableRawData,
  isScatterRawData,
  isCurrentChartType,
  isMetricChartType,
  isScatterChartType,
  isTableChartType,
} from '../widgets.utils';
import { defaultCheckChartDataStatus } from './checkChartDataStatus';
import { checkCurrentChartTypeDataStatus } from './current/checkCurrentRawDataStatus';
import { checkTableChartTypeDataStatus } from './table/checkTableRawDataStatus';
import { checkMetricChartTypeDataStatus } from './metric/checkMetricRawDataStatus';

export function useChartDataStatus(
  chartDataType: ChartDataType,
  chartType: ChartType,
  rawData: ComputedRef<ChartRawData>,
  chartDataStatus: ComputedRef<WidgetChartDataStatusInfo[]>,
  mode: ComputedRef<WidgetModeType | undefined>,
  chartData: ComputedRef<WidgetChartData[] | undefined>,
  emit: { (event: 'changeChartDataStatus', statusList: WidgetChartDataStatusInfo[]): void },
) {
  const { t } = useInternational();
  const ctx = getCurrentInstance()!.appContext.config.globalProperties;

  const { getStatInfo } = useStatInfoStore();

  watch([rawData, chartDataStatus], ([newRawData, newChartDataStatus]) => {
    if (mode.value !== 'preview') {
      return;
    }

    let chartDataStatusList: WidgetChartDataStatusInfo[] = [];

    if (
      chartDataType === 'metrics' &&
      isMetricChartType(chartType) &&
      isMetricRawData(newRawData)
    ) {
      chartDataStatusList = checkMetricChartTypeDataStatus()(chartType)(
        newRawData,
        newChartDataStatus,
      );
    } else if (
      chartDataType === 'current' &&
      isCurrentChartType(chartType) &&
      isCurrentRawData(newRawData)
    ) {
      chartDataStatusList = checkCurrentChartTypeDataStatus(t)(chartType)(
        newRawData,
        newChartDataStatus,
      );
    } else if (
      chartDataType === 'table' &&
      isTableChartType(chartType) &&
      isTableRawData(newRawData)
    ) {
      chartDataStatusList = checkTableChartTypeDataStatus(t)(chartType)(
        newRawData,
        newChartDataStatus,
      );
    } else if (
      chartDataType === 'scatter' &&
      isScatterChartType(chartType) &&
      isScatterRawData(newRawData)
    ) {
      chartDataStatusList = defaultCheckChartDataStatus(chartType)(newRawData, newChartDataStatus);
    }

    if (chartDataStatusList?.some((status) => status.errorCode === 'CE003')) {
      showErrorMsg(ctx, t('ERROR.CHART_DATA.CE003'));
    }

    // CE001 에러만 화면에서 처리
    chartData.value?.forEach(({ dataId, id }, chartDataIndex) => {
      if (chartDataStatusList[chartDataIndex]?.status === 'fail') {
        return;
      }

      const matchedStatInfoDataType = getStatInfo({ statId: dataId })?.dataType;

      if (!matchedStatInfoDataType?.includes(chartDataType)) {
        chartDataStatusList[chartDataIndex] = {
          status: 'fail',
          type: 'api',
          reason: t('ERROR.CHART_DATA.CE001'),
          chartDataId: id,
          errorCode: 'CE001',
        };
      }
    });

    emit('changeChartDataStatus', chartDataStatusList);
  });
}
