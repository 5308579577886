import { WasType } from '@/application/utils/types';
import { getWasInfoApplicationWasControllerAxios } from '@/openapi/application/api/application-was-controller-api';
import { getContainerInfoInfraContainerV7ControllerAxios } from '@/openapi/infra/api/infra-container-v7-controller-api';
import {
  getHostInfoInfraHostV7ControllerAxios,
  getProcessInfraHostV7ControllerAxios,
} from '@/openapi/infra/api/infra-host-v7-controller-api';
import { getDeviceInfoNdmMonitoringControllerAxios } from '@/openapi/ndm/api/ndm-monitoring-controller-api';
import { getServiceDetailXmServiceControllerAxios } from '@/openapi/service/api/xm-service-controller-api';
import { getTargetCheckMetaDatabaseInstanceV8ControllerAxios } from '@/openapi/metav8Over/api/meta-database-instance-v8-controller-api';
import {
  type AdditionForSqlDetailMySQL,
  type AdditionForSqlDetailOracle,
  type AdditionForSqlDetailPG,
  type AdditionForSqlDetailSqlServer,
} from '@/database/components/sqlDetail/instanceSqlDetail.types';
import { getInstanceServiceAwsInstanceControllerAxios } from '@/openapi/cloud/api/aws-instance-controller-api';
import { getInformationAwsDetailControllerAxios } from '@/openapi/cloud/api/aws-detail-controller-api';
import { AWS_SERVICE } from '@/cloud/utils/define';
import { ProcessAddition } from '@/infrastructure/views/hostView/hostDetail/processGrid.setup';
import { FRAME_NAMES } from '../define/apiTrace.define';
import { DATABASE_DETAIL, INFRA_DETAIL, KUBER_DETAIL } from '../define/slideDetail.define';
import { AddTabPanelInfo, PanelInfo, TOTAL_DETAIL } from '../stores/slide-detail';
import { DB_TYPE } from '../utils';
import { standardTimeToUtcZeroTime } from '../utils/commonUtils';
import { i18n } from '../locale';

interface DetailInfo {
  targetId: string;
  targetName?: string;
  addition?: Record<string, any>;
}

const getWasTabPanelInfo = async ({
  targetId,
  targetName = '',
  targetType = '',
  frameName = FRAME_NAMES.WAS_DETAIL.INFORMATION,
}): Promise<AddTabPanelInfo> => {
  if (targetId && targetName && targetType) {
    return {
      type: TOTAL_DETAIL.WAS,
      name: targetName,
      was: {
        wasId: targetId,
        type: targetType as WasType,
      },
    };
  }

  const { data } = await getWasInfoApplicationWasControllerAxios({
    wasId: targetId,
    frameName,
  });
  const { wasName, type } = data?.data?.[0] ?? {};
  return {
    type: TOTAL_DETAIL.WAS,
    name: targetName || wasName || '',
    was: {
      wasId: targetId,
      type: (type as WasType) ?? 'None',
    },
  };
};

interface K8sPanelParam {
  type: (typeof KUBER_DETAIL)[keyof typeof KUBER_DETAIL];
  targetId: string;
  targetName?: string;
}
const getKubernetesTabPanelInfo = async ({
  type,
  targetId,
  targetName = '',
}: K8sPanelParam): Promise<AddTabPanelInfo> => {
  return {
    type,
    name: targetName || '',
    addition: {
      uid: targetId,
    },
  };
};

const getPodTabPanelInfo = async ({ targetId, targetName = '' }): Promise<AddTabPanelInfo> => {
  return {
    type: TOTAL_DETAIL.POD,
    name: targetName || '',
    addition: {
      uid: targetId,
    },
  };
};

const getNameSpaceTabPanelInfo = async ({
  targetId,
  targetName = '',
}): Promise<AddTabPanelInfo> => {
  return {
    type: TOTAL_DETAIL.NAMESPACE,
    name: targetName || '',
    addition: {
      uid: targetId,
    },
  };
};

const getNodeTabPanelInfo = async ({ targetId, targetName = '' }): Promise<AddTabPanelInfo> => {
  return {
    type: TOTAL_DETAIL.NODE,
    name: targetName || '',
    addition: {
      uid: targetId,
    },
  };
};

const getDbTabPanelInfo = async ({ targetId, targetName = '' }): Promise<AddTabPanelInfo> => {
  const {
    data: { data: { isCluster = 'false', subType = '' } = {} },
  } = await getTargetCheckMetaDatabaseInstanceV8ControllerAxios({
    instanceId: targetId,
  });
  if (isCluster === 'true') {
    return {
      type: TOTAL_DETAIL.CLUSTER,
      name: targetName,
      cluster: {
        instanceId: targetId,
      },
    };
  }
  return {
    type: subType === 'mongos' ? TOTAL_DETAIL.SHARDED_CLUSTER : TOTAL_DETAIL.INSTANCE,
    name: targetName,
    instance: {
      instanceId: targetId,
    },
    addition: { subType },
  };
};

const getHostTabPanelInfo = async ({
  targetId,
  targetName = '',
  frameName = FRAME_NAMES.HOST_DETAIL.INFORMATION,
}): Promise<AddTabPanelInfo> => {
  const { data } = await getHostInfoInfraHostV7ControllerAxios({
    hostId: targetId,
    frameName,
  });
  const { hostName, server, containerHostId } = data?.data?.[0]?.meta ?? {};
  const addition:
    | {
        server: 'host';
      }
    | { server: 'container_host'; containerHostId: string } =
    server === 'host'
      ? { server: 'host' }
      : { server: 'container_host', containerHostId: containerHostId ?? '' };
  return {
    type: TOTAL_DETAIL.HOST,
    host: targetId,
    name: targetName || hostName || '',
    addition,
  };
};

const getHostProcessTabPanelInfo = async ({
  targetId,
  targetName = '',
}): Promise<AddTabPanelInfo> => {
  const panelInfo = await getHostTabPanelInfo({ targetId, targetName });
  return {
    ...panelInfo,
    state: {
      selectedTab: 'process',
    },
  };
};

const getHostProcessDetailTabPanelInfo = async ({
  targetId,
  pid,
  targetName = '',
}): Promise<AddTabPanelInfo> => {
  const { data } = await getProcessInfraHostV7ControllerAxios({
    hostId: targetId,
    frameName: FRAME_NAMES.HOST_DETAIL.PROCESS,
  });
  const process = data?.data?.find((item) => item.pid === pid);
  if (!process) {
    return getHostProcessTabPanelInfo({ targetId, targetName });
  }

  const addition: ProcessAddition = {
    hostId: targetId,
    pid: process.pid!,
    ppid: process.ppid!,
    processName: process.processName!,
    args: process.args!,
    userName: process.userName!,
    startTime: process.startTime!,
    cores: process.cpuLogicalCount!,
  };

  return {
    type: TOTAL_DETAIL.PROCESS_DETAIL,
    name: targetName || process.processName || '',
    host: targetId,
    pid: process.pid?.toString() ?? '',
    addition,
  };
};

const getContainerTabPanelInfo = async ({
  targetId,
  targetName = '',
  frameName = FRAME_NAMES.CONTAINER_DETAIL.INFORMATION,
}): Promise<AddTabPanelInfo> => {
  const { data } = await getContainerInfoInfraContainerV7ControllerAxios({
    containerId: targetId,
    frameName,
  });
  const { server } = data?.data?.[0] ?? {};
  const { podUid, containerName: k8sName } = data?.data?.[0]?.kubeContainer ?? {};
  const { containerName, hostId } = data?.data?.[0]?.containerHost ?? {};
  const addition: PanelInfo<'Container'>['addition'] =
    server === 'k8s'
      ? { server: 'k8s', podUid: podUid ?? '' }
      : { server: 'container_host', containerHostId: hostId ?? '' };
  return {
    type: TOTAL_DETAIL.CONTAINER,
    name: targetName || k8sName || containerName || '',
    container: targetId,
    addition,
  };
};

const getNetworkDeviceTabPanelInfo = async ({
  targetId,
  targetName = '',
  frameName = FRAME_NAMES.NETWORK_DEVICE_DETAIL.INFORMATION,
}): Promise<AddTabPanelInfo> => {
  const { data } = await getDeviceInfoNdmMonitoringControllerAxios({
    deviceId: targetId,
    frameName,
  });
  const { aliasName, ip } = data?.data?.[0] ?? {};
  return {
    type: TOTAL_DETAIL.NETWORK_DEVICE,
    name: `${aliasName || targetName} | IP : ${ip}`,
    init: true,
    addition: {
      deviceId: targetId,
      networkDeviceName: aliasName || targetName,
    },
  };
};

const getNetworkObjectTabPanelInfo = ({ targetId, targetName = '' }): AddTabPanelInfo => {
  return {
    type: INFRA_DETAIL.NETWORK_OBJECT,
    name: targetName,
    init: true,
    addition: {
      networkObjectId: targetId,
    },
  };
};

const getNetworkInterfaceTabPanelInfo = async ({
  targetId,
  targetName = '',
  frameName = FRAME_NAMES.NETWORK_DEVICE_DETAIL.INFORMATION,
}): Promise<AddTabPanelInfo> => {
  // NOTE: networkInterface의 경우 targetId가 targetId|interfaceId 형태로 들어온다.
  const [deviceId] = targetId.split('|');
  const { data } = await getDeviceInfoNdmMonitoringControllerAxios({
    deviceId,
    frameName,
  });
  const { aliasName, ip } = data?.data?.[0] ?? {};
  return {
    type: TOTAL_DETAIL.NETWORK_DEVICE,
    name: `${aliasName || targetName} | IP : ${ip}`,
    init: true,
    state: {
      selectedTab: 'interface',
    },
    addition: {
      deviceId,
      networkDeviceName: aliasName || targetName,
    },
  };
};

const getAwsTabPanelInfo = async ({
  targetId,
  targetName = '',
}: DetailInfo): Promise<AddTabPanelInfo | null> => {
  const {
    data: { data = [] },
  } = await getInstanceServiceAwsInstanceControllerAxios({ instanceId: targetId });

  const service = data[0]?.awsService;

  if (!service) return null;

  const getAwsDetailInfo = async () => {
    const {
      data: { data: detailInfo = [] },
    } = await getInformationAwsDetailControllerAxios({
      serviceType: service,
      instanceId: targetId,
      frameName: `${FRAME_NAMES.CLOUD.AWS_DETAIL.INFORMATION} - ${service}`,
    });
    return detailInfo;
  };

  const handleTargetElasticache = async () => {
    const detailInfo = await getAwsDetailInfo();

    const elasticacheService =
      service === AWS_SERVICE.ELASTICACHE_SERVERLESS
        ? AWS_SERVICE.ELASTICACHE_SERVERLESS
        : AWS_SERVICE.ELASTICACHE_CLUSTER;

    const instanceId = detailInfo[0]?.clusterId || targetId;

    let instanceType = '';
    switch (service) {
      case AWS_SERVICE.ELASTICACHE_CLUSTER: {
        instanceType = detailInfo[0]?.clusterMode === 'enabled' ? 'SHARD' : 'NODE';
        break;
      }
      case AWS_SERVICE.ELASTICACHE_SHARD: {
        instanceType = 'SHARD';
        break;
      }
      case AWS_SERVICE.ELASTICACHE_NODE: {
        instanceType = 'NODE';
        break;
      }
      default:
        break;
    }

    return {
      type: TOTAL_DETAIL.AWS_ELASTICACHE,
      name: targetName,
      addition: {
        instanceId,
        service: elasticacheService,
        instanceType,
      },
    };
  };

  const handleTargetDocumentDB = async () => {
    const detailInfo = await getAwsDetailInfo();

    const [, instanceRole] = detailInfo[0]?.role?.split(' ');
    const title = instanceRole
      ? `${i18n.global.t(`WORD.${instanceRole.toLocaleUpperCase()}`)}: ${targetName}`
      : undefined;

    return {
      type: TOTAL_DETAIL.AWS_DOCUMENTDB,
      name: targetName,
      title,
      addition: {
        instanceId: targetId,
        service,
        instanceType: instanceRole,
      },
    };
  };

  switch (service) {
    case AWS_SERVICE.VPC:
    case AWS_SERVICE.AURORA:
    case AWS_SERVICE.LIGHTSAIL_DATABASE:
    case AWS_SERVICE.LIGHTSAIL_INSTANCE:
    case AWS_SERVICE.EC2:
    case AWS_SERVICE.S3:
    case AWS_SERVICE.LAMBDA:
    case AWS_SERVICE.RDS:
      // 대시보드 연계 임시 방지 (Ec2, S3, lambda, RDS) - 추후에 풀릴 예정
      return null;
    case AWS_SERVICE.DOCUMENTDB: {
      return handleTargetDocumentDB();
    }
    case AWS_SERVICE.ELASTICACHE_CLUSTER:
    case AWS_SERVICE.ELASTICACHE_NODE:
    case AWS_SERVICE.ELASTICACHE_SHARD:
    case AWS_SERVICE.ELASTICACHE_SERVERLESS: {
      return handleTargetElasticache();
    }
    default:
      return {
        type: TOTAL_DETAIL[`AWS_${service}`],
        name: targetName,
        addition: {
          instanceId: targetId,
          service,
        },
      };
  }
};

const getServiceTabPanelInfo = async ({
  targetId,
  targetName = '',
  frameName = FRAME_NAMES.BUSINESS_DETAIL.INFORMATION,
}): Promise<AddTabPanelInfo> => {
  const { data } = await getServiceDetailXmServiceControllerAxios({
    serviceId: targetId,
    frameName,
  });
  const { name } = data?.data?.[0] ?? {};

  return {
    type: TOTAL_DETAIL.SERVICE_LIST,
    name: targetName || name || '',
    addition: {
      serviceId: targetId,
    },
  };
};

const getSqlTabPanelInfo = async (detailInfo: DetailInfo): Promise<AddTabPanelInfo | null> => {
  switch (detailInfo.addition?.dbType) {
    case DB_TYPE.ORACLE: {
      const addition: AdditionForSqlDetailOracle = {
        dbType: DB_TYPE.ORACLE,
        instanceId: detailInfo.addition.instanceId,
        sqlId: detailInfo.addition.sqlId,
        fromTime: detailInfo.addition.fromTime,
        toTime: detailInfo.addition.toTime,
        schema: detailInfo.addition.schema,
        module: detailInfo.addition.module,
        planHashValue: detailInfo.addition.planHashValue,
      };
      return {
        type: DATABASE_DETAIL.SQL,
        name: `${detailInfo.addition.sqlId}`,
        addition,
      };
    }
    case DB_TYPE.MYSQL: {
      const addition: AdditionForSqlDetailMySQL = {
        dbType: DB_TYPE.MYSQL,
        digest: detailInfo.addition.digest,
        schema: detailInfo.addition.schema,
        instanceId: detailInfo.addition.instanceId,
        fromTime: detailInfo.addition.fromTime,
        toTime: detailInfo.addition.toTime,
        sqlId: detailInfo.addition.sqlId,
        sqlTextHashId: detailInfo.addition.sqlTextHashId,
      };
      return {
        type: DATABASE_DETAIL.SQL,
        name: `${detailInfo.addition.digest}`,
        addition,
      };
    }
    case DB_TYPE.SQLSERVER: {
      const addition: AdditionForSqlDetailSqlServer = {
        dbType: DB_TYPE.SQLSERVER,
        database: detailInfo.addition.databaseName,
        object: detailInfo.addition.objectName,
        instanceId: detailInfo.addition.instanceId,
        sqlId: detailInfo.addition.sqlId,
        fromTime: detailInfo.addition.fromTime,
        toTime: detailInfo.addition.toTime,
        sqlTextHashId: detailInfo.addition.sqlTextHashId,
        sessionId: detailInfo.addition.sessionId,
        sqlHash: detailInfo.addition.sqlHash,
        sqlHandle: detailInfo.addition.sqlHandle,
        statementStartOffset: detailInfo.addition.statementStartOffset,
        statementEndOffset: detailInfo.addition.statementEndOffset,
        planHash: detailInfo.addition.planHash,
        planHandle: detailInfo.addition.planHandle,
      };
      return {
        type: DATABASE_DETAIL.SQL,
        name: `${detailInfo.addition.sqlId}`,
        addition,
      };
    }
    case DB_TYPE.POSTGRESQL: {
      const addition: AdditionForSqlDetailPG = {
        dbType: DB_TYPE.POSTGRESQL,
        instanceId: detailInfo.addition.instanceId,
        sqlId: detailInfo.addition.sqlId,
        queryId: detailInfo.addition.queryId,
        dbId: detailInfo.addition.dbId,
        dbUserId: detailInfo.addition.dbUserId,
        fromTime: detailInfo.addition.fromTime,
        toTime: detailInfo.addition.toTime,
      };
      return {
        type: DATABASE_DETAIL.SQL,
        name: `${detailInfo.addition.sqlId}`,
        addition,
      };
    }
    default:
      return null;
  }
};

const getSessionTabPanelInfo = async (detailInfo: DetailInfo): Promise<AddTabPanelInfo | null> => {
  switch (detailInfo.addition?.dbType) {
    case DB_TYPE.ORACLE:
      return {
        type: TOTAL_DETAIL.SESSION,
        name: `${detailInfo.addition.sid}`,
        session: {
          dbType: DB_TYPE.ORACLE,
          [DB_TYPE.ORACLE]: {
            instanceId: detailInfo.addition.instanceId,
            sid: detailInfo.addition.sid,
            serialNumber: detailInfo.addition.serialNumber,
            groupId: '',
            fromTime: detailInfo.addition.fromTime,
            toTime: detailInfo.addition.toTime,
          },
        },
      };
    case DB_TYPE.MYSQL:
      return {
        type: TOTAL_DETAIL.SESSION,
        name: `${detailInfo.addition.threadId}`,
        session: {
          dbType: DB_TYPE.MYSQL,
          [DB_TYPE.MYSQL]: {
            instanceId: detailInfo.addition.instanceId,
            threadId: detailInfo.addition.threadId,
            digest: detailInfo.addition.digest,
            init: true, // search session을 "처음" 띄울 때 fromTime, toTime을 현재~과거 기준이 아닌 과거~과거로 설정할 경우 true
            fromTime: standardTimeToUtcZeroTime(detailInfo.addition.fromTime),
            toTime: standardTimeToUtcZeroTime(detailInfo.addition.toTime),
          },
        },
      };
    case DB_TYPE.SQLSERVER:
      return {
        type: TOTAL_DETAIL.SESSION,
        name: `${detailInfo.addition.sessionId}`,
        session: {
          dbType: DB_TYPE.SQLSERVER,
          [DB_TYPE.SQLSERVER]: {
            instanceId: detailInfo.addition.instanceId,
            sessionId: detailInfo.addition.sessionId,
            init: true,
            fromTime: standardTimeToUtcZeroTime(detailInfo.addition.fromTime),
            toTime: standardTimeToUtcZeroTime(detailInfo.addition.toTime),
          },
        },
      };
    default:
      return null;
  }
};

const getActiveTxnTabPanelInfo = async ({
  targetId,
  targetName = '',
  addition: { chartData } = {},
}: DetailInfo): Promise<AddTabPanelInfo> => {
  return {
    type: TOTAL_DETAIL.ACTIVE_TRANSACTION,
    name: targetName || '',
    addition: {
      targetId,
      chartData,
    },
  };
};

const getBrokerTabPanelInfo = async ({ targetId }: DetailInfo): Promise<AddTabPanelInfo> => {
  const [instanceId, name] = targetId.split('|');
  return {
    type: TOTAL_DETAIL.BROKER,
    name,
    addition: {
      instanceId,
      dbType: DB_TYPE.CUBRID,
    },
  };
};

export {
  getWasTabPanelInfo,
  getKubernetesTabPanelInfo,
  getPodTabPanelInfo,
  getNameSpaceTabPanelInfo,
  getNodeTabPanelInfo,
  getDbTabPanelInfo,
  getHostTabPanelInfo,
  getHostProcessTabPanelInfo,
  getHostProcessDetailTabPanelInfo,
  getContainerTabPanelInfo,
  getNetworkDeviceTabPanelInfo,
  getNetworkObjectTabPanelInfo,
  getNetworkInterfaceTabPanelInfo,
  getAwsTabPanelInfo,
  getServiceTabPanelInfo,
  getSqlTabPanelInfo,
  getSessionTabPanelInfo,
  getActiveTxnTabPanelInfo,
  getBrokerTabPanelInfo,
};
