import { LOGIN_CUSTOMER } from '@/common/define/common.define';
import { LoginCustomer } from '@/login/utils/types';
import { XmServerProperty } from '@/openapi/secure/model';
import { isEmpty } from 'lodash-es';
import { defineStore } from 'pinia';
import { Component, computed, defineAsyncComponent, ref } from 'vue';

export type PropertyType = XmServerProperty;
type LoginComponentMap = Record<LoginCustomer, Component>;

interface CustomOption {
  useLoginAnimation?: boolean;
  useLoadingAnimation?: boolean;
  useLoginTitle?: boolean;
}

const loginComponent: LoginComponentMap = {
  EXEMONE: defineAsyncComponent(() => import('@/login/components/loginForm/LoginForm.vue')),
  HYUNDAICAPITAL: defineAsyncComponent(
    () => import('@/login/components/loginForm/hyundaicapital/HyundaiCapitalLoginForm.vue'),
  ),
  HYUNDAICARD: defineAsyncComponent(
    () => import('@/login/components/loginForm/hyundaicard/HyundaiCardLoginForm.vue'),
  ),
  LGE: defineAsyncComponent(() => import('@/login/components/loginForm/LoginForm.vue')),
};

const loadStyle: Record<LoginCustomer, null | (() => Promise<typeof import('*.scss')>)> = {
  [LOGIN_CUSTOMER.EXEMONE]: null,
  [LOGIN_CUSTOMER.HYUNDAICAPITAL]: () => import('@/common/styles/customer/_hyundaicapital.scss'),
  [LOGIN_CUSTOMER.HYUNDAICARD]: null,
  [LOGIN_CUSTOMER.LGE]: () => import('@/common/styles/customer/_lge.scss'),
};

export const usePropertyStore = defineStore('property', () => {
  const propertyData = ref({} as PropertyType);
  const isSetProperty = computed(() => !isEmpty(propertyData.value));
  const isSsoMode = computed(() => !!propertyData.value?.sso?.ssoMode);
  const isSessionTimerActive = () => !!propertyData.value.sessionTimeoutMode;
  const isRestrictDuplicateUsers = () => !!propertyData.value.restrictDuplicateUsers;
  const isValidUser = () =>
    Object.keys(LOGIN_CUSTOMER).includes(
      propertyData.value.loginCustomer ?? LOGIN_CUSTOMER.EXEMONE,
    );
  const loginCustomer = computed(
    () =>
      (isValidUser() ? propertyData.value?.loginCustomer : LOGIN_CUSTOMER.EXEMONE) ||
      LOGIN_CUSTOMER.EXEMONE,
  );
  const loginOptions = computed<Record<LoginCustomer, CustomOption>>(() => {
    return {
      [LOGIN_CUSTOMER.EXEMONE]: {
        useLoginAnimation: propertyData.value.loginAnimeEnable,
        useLoadingAnimation: propertyData.value.loginAnimeEnable,
        useLoginTitle: true,
      },
      [LOGIN_CUSTOMER.HYUNDAICAPITAL]: {
        useLoginAnimation: propertyData.value.loginAnimeEnable,
        useLoadingAnimation: propertyData.value.loginAnimeEnable,
        useLoginTitle: false,
      },
      [LOGIN_CUSTOMER.HYUNDAICARD]: {
        useLoginAnimation: propertyData.value.loginAnimeEnable,
        useLoadingAnimation: propertyData.value.loginAnimeEnable,
        useLoginTitle: true,
      },
      [LOGIN_CUSTOMER.LGE]: {
        useLoginAnimation: false,
        useLoadingAnimation: false,
        useLoginTitle: true,
      },
    };
  });

  const getOptions = {
    loginOptions: () => loginOptions.value[loginCustomer.value],
    loginComponent: (customer?: LoginCustomer) => loginComponent[customer ?? loginCustomer.value],
    loadStyle: () => loadStyle[loginCustomer.value]?.(),
  };

  const isMatchedCustomer = (customer: LoginCustomer) => loginCustomer.value === customer;

  return {
    propertyData,
    isSetProperty,
    isSsoMode,
    isSessionTimerActive,
    loginCustomer,
    getOptions,
    isMatchedCustomer,
    isRestrictDuplicateUsers,
  };
});
