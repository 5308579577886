import { Component, computed, ref, shallowRef } from 'vue';
import { cloneDeep, isEqual, isNil, omit } from 'lodash-es';
import { useRoute } from 'vue-router';
import dayjs from 'dayjs';
import type {
  PageAnalysisDetailInfo,
  RumApplicationDetailInfo,
  TransactionDetailInfo,
  WasDetailInfo,
} from '@/application/utils/types';
import { BASE_MENU_VALUE } from '@/common/components/organisms/baseMenu/baseMenu.define';
import {
  ALERT_DETAIL,
  APPLICATION_DETAIL,
  CLOUD_DETAIL,
  CONFIG_DETAIL,
  CUSTOM_DETAIL,
  DATABASE_DETAIL,
  DETAIL_COMPONENTS,
  INFRA_DETAIL,
  KUBER_DETAIL,
  SERVICE_DETAIL,
  TOOL_DETAIL,
} from '@/common/define/slideDetail.define';
import type { BrowserErrorTrackingDetailInfo } from '@/application/components/browserErrorTrackingDetail/browserErrorTrackingDetail.types';
import type { InstanceSessionDetailInfo } from '@/database/components/sessionDetail/instanceSessionDetail.setup';
import type { AdditionForSqlDetail } from '@/database/components/sqlDetail/instanceSqlDetail.types';
import type { ProcessAddition } from '@/infrastructure/views/hostView/hostDetail/processGrid.setup';
import type { InstanceDetailInfo } from '@/database/components/instanceDetail/types';
import type { RolePermissionKeyType } from '@/config/views/permission/rolePermission/rolePermission.type';
import type { AgentDetailAddition } from '@/config/components/agentDetail/agentDetail.types';
import type { AwsDetailAddition } from '@/cloud/utils/types';
import type { AlertDetailInfo } from '@/alert/utils/types';
import { useRolePermission } from '@/common/permission/permission.utils';
import { ROLE_PERMISSION_KEY } from '@/common/define/rolePermission.define';
import { VIEW_MODE } from '@/common/define/common.define';
import { KeyBrowserInfo } from '@/database/components/keyBrowserDetail/keyBrowserDetail.types';
import { getDetailKey } from '../utils/string.utils';

type KuberDetail = typeof KUBER_DETAIL;
type InfraDetail = typeof INFRA_DETAIL;
type ApplicationDetail = typeof APPLICATION_DETAIL;
type AlertDetail = typeof ALERT_DETAIL;
type ServiceDetail = typeof SERVICE_DETAIL;
type DatabaseDetail = typeof DATABASE_DETAIL;
type ToolDetail = typeof TOOL_DETAIL;
type ConfigDetail = typeof CONFIG_DETAIL;
type CloudDetail = typeof CLOUD_DETAIL;
type CustomDetail = typeof CUSTOM_DETAIL;

export const TOTAL_DETAIL = {
  ...KUBER_DETAIL,
  ...INFRA_DETAIL,
  ...APPLICATION_DETAIL,
  ...ALERT_DETAIL,
  ...DATABASE_DETAIL,
  ...SERVICE_DETAIL,
  ...TOOL_DETAIL,
  ...CONFIG_DETAIL,
  ...CLOUD_DETAIL,
  ...CUSTOM_DETAIL,
};

export type DetailType =
  | KuberDetail[keyof KuberDetail]
  | InfraDetail[keyof InfraDetail]
  | ApplicationDetail[keyof ApplicationDetail]
  | AlertDetail[keyof AlertDetail]
  | ServiceDetail[keyof ServiceDetail]
  | DatabaseDetail[keyof DatabaseDetail]
  | ToolDetail[keyof ToolDetail]
  | ConfigDetail[keyof ConfigDetail]
  | CloudDetail[keyof CloudDetail]
  | CustomDetail[keyof CustomDetail];

export interface BaseTabPanelInfo {
  type: DetailType;
  name: string;
  title?: string;
  init?: boolean; // history 초기화 여부
  state?: {
    selectedTab?: string;
    searchWord?: string;
    keepOpen?: boolean; // 슬라이드 창이 열려있을 때 동일 요소 클릭 시 슬라이드 창이 닫히는 기능 disable
    [key: string]: any;
  };
  addition?: Record<string, any>;
  session?: Record<string, any>;
  rolePermissionKey?: RolePermissionKeyType; // type으로 권한 체크를 하였으나, 혹시 type으로 체크가 힘든 경우 따로 지정
}

interface WasPanelInfo extends BaseTabPanelInfo {
  type: typeof TOTAL_DETAIL.WAS;
  was: WasDetailInfo;
}

interface TransactionPanelInfo extends BaseTabPanelInfo {
  type: typeof TOTAL_DETAIL.TRANSACTION;
  transaction: Omit<TransactionDetailInfo, 'traceKey'>;
}

interface ActiveTransactionPanelInfo extends BaseTabPanelInfo {
  type: typeof TOTAL_DETAIL.ACTIVE_TRANSACTION;
}

interface URLMonitoringInfo extends BaseTabPanelInfo {
  type: typeof APPLICATION_DETAIL.URL_MONITORING;
}
interface PagePerformanceMonitoringInfo extends BaseTabPanelInfo {
  type: typeof TOTAL_DETAIL.PAGE_PERFORMANCE_MONITORING;
  application: RumApplicationDetailInfo;
}

interface PagePerformanceAnalysisInfo extends BaseTabPanelInfo {
  type: typeof TOTAL_DETAIL.PAGE_PERFORMANCE_ANALYSIS;
  page: PageAnalysisDetailInfo;
}

interface BrowserErrorTrackingInfo extends BaseTabPanelInfo {
  type: typeof TOTAL_DETAIL.BROWSER_ERROR_TRACKING;
  addition: BrowserErrorTrackingDetailInfo;
}

interface AlertPanelInfo extends BaseTabPanelInfo {
  type: typeof TOTAL_DETAIL.ALERT;
  addition: AlertDetailInfo;
}

interface SessionPanelInfo extends BaseTabPanelInfo {
  type: typeof TOTAL_DETAIL.SESSION;
  session: InstanceSessionDetailInfo;
}

interface SqlPanelInfo extends BaseTabPanelInfo {
  type: typeof TOTAL_DETAIL.SQL;
  addition: AdditionForSqlDetail;
}

export interface InstancePanelInfo extends BaseTabPanelInfo {
  type: typeof TOTAL_DETAIL.INSTANCE;
  instance: InstanceDetailInfo;
}

export interface ClusterPanelInfo extends BaseTabPanelInfo {
  type: typeof TOTAL_DETAIL.CLUSTER;
  cluster: {
    instanceId: string;
    dbType?: string;
  };
}
export interface ShardedClusterPanelInfo extends BaseTabPanelInfo {
  type: typeof TOTAL_DETAIL.SHARDED_CLUSTER;
  instance: InstanceDetailInfo;
}

interface KeyBrowserPanelInfo extends BaseTabPanelInfo {
  type: typeof TOTAL_DETAIL.KEY_BROWSER;
  addition: KeyBrowserInfo;
}

interface ContainerPanelInfo extends BaseTabPanelInfo {
  type: typeof TOTAL_DETAIL.CONTAINER;
  container: string;
  addition:
    | {
        server: 'k8s';
        podUid?: string;
      }
    | { server: 'container_host'; containerHostId: string };
}

interface HostDiskPanelInfo extends BaseTabPanelInfo {
  type: typeof TOTAL_DETAIL.DISK_DETAIL;
  host: string;
}

interface HostNetworkPanelInfo extends BaseTabPanelInfo {
  type: typeof TOTAL_DETAIL.NETWORK_DETAIL;
  host: string;
}

interface HostProcessPanelInfo extends BaseTabPanelInfo {
  type: typeof TOTAL_DETAIL.PROCESS_DETAIL;
  host: string;
  pid: string;
  addition: ProcessAddition;
}

interface HostPanelInfo extends BaseTabPanelInfo {
  type: typeof TOTAL_DETAIL.HOST;
  host: string;
  addition:
    | { server: 'host'; tabValue?: string }
    | { server: 'container_host'; containerHostId: string; tabValue?: string };
}

interface NetworkDevicePanelInfo extends BaseTabPanelInfo {
  type: typeof TOTAL_DETAIL.NETWORK_DEVICE;
  addition: {
    deviceId: string;
    networkDeviceName: string;
  };
}
interface NetworkObjectPanelInfo extends BaseTabPanelInfo {
  type: typeof TOTAL_DETAIL.NETWORK_OBJECT;
  addition: {
    networkObjectId: string;
  };
}

interface CloudAwsPanelDetail extends BaseTabPanelInfo {
  type: (typeof CLOUD_DETAIL)[keyof CloudDetail];
  addition: AwsDetailAddition;
}

export interface ScriptManagerHistoryPanelInfo extends BaseTabPanelInfo {
  type: typeof TOTAL_DETAIL.SCRIPT_DETAIL;
  addition: {
    executionId: string;
  };
}

export interface KuberDetailPanelInfo extends BaseTabPanelInfo {
  type: (typeof KUBER_DETAIL)[keyof KuberDetail];
  addition: {
    uid: string;
  };
}

interface ServiceDetailPanelInfo extends BaseTabPanelInfo {
  type: (typeof SERVICE_DETAIL)[keyof ServiceDetail];
  addition: {
    serviceId: string;
  };
}

interface ParameterDetailPanelInfo extends BaseTabPanelInfo {
  type: typeof TOTAL_DETAIL.PARAMETER;
  addition: {
    dbType: string;
    instanceId: string;
  };
}

interface BrokerPanelInfo extends BaseTabPanelInfo {
  type: typeof TOTAL_DETAIL.BROKER;
  addition: {
    dbType: string;
    instanceId: string;
  };
}

interface QueryManagerDetailPanelInfo extends BaseTabPanelInfo {
  type: typeof TOTAL_DETAIL.QUERY;
  addition: {
    historyId: string;
  };
}

interface UsageTrendPanelInfo extends BaseTabPanelInfo {
  type: typeof TOTAL_DETAIL.USAGE_TREND;
}

interface ConfigAgentPanelInfo extends BaseTabPanelInfo {
  type: typeof TOTAL_DETAIL.AGENT;
  addition: AgentDetailAddition;
}

interface ConfigExecutionPanelInfo extends BaseTabPanelInfo {
  type: typeof TOTAL_DETAIL.EXECUTION;
  addition: {
    executionId: string;
  };
}

interface CustomPanelInfo extends BaseTabPanelInfo {
  type: typeof TOTAL_DETAIL.CUSTOM;
}

/* eslint-disable */
/* prettier-ignore */
export type PanelInfo<Type extends DetailType> =
  Type extends typeof TOTAL_DETAIL.WAS ? WasPanelInfo :
  Type extends typeof TOTAL_DETAIL.TRANSACTION ? TransactionPanelInfo :
  Type extends typeof TOTAL_DETAIL.ACTIVE_TRANSACTION ? ActiveTransactionPanelInfo :
  Type extends typeof TOTAL_DETAIL.PAGE_PERFORMANCE_MONITORING ? PagePerformanceMonitoringInfo :
  Type extends typeof TOTAL_DETAIL.PAGE_PERFORMANCE_ANALYSIS ? PagePerformanceAnalysisInfo :
  Type extends typeof TOTAL_DETAIL.BROWSER_ERROR_TRACKING ? BrowserErrorTrackingInfo :
  Type extends typeof TOTAL_DETAIL.URL_MONITORING ? URLMonitoringInfo :
  Type extends typeof TOTAL_DETAIL.ALERT ? AlertPanelInfo :
  Type extends typeof TOTAL_DETAIL.SESSION ? SessionPanelInfo :
  Type extends typeof TOTAL_DETAIL.SQL ? SqlPanelInfo :
  Type extends typeof TOTAL_DETAIL.BROKER ? BrokerPanelInfo :
  Type extends typeof TOTAL_DETAIL.PARAMETER ? ParameterDetailPanelInfo :
  Type extends typeof TOTAL_DETAIL.INSTANCE ? InstancePanelInfo :
  Type extends typeof TOTAL_DETAIL.CLUSTER ? ClusterPanelInfo :
  Type extends typeof TOTAL_DETAIL.SHARDED_CLUSTER ? ShardedClusterPanelInfo:
  Type extends typeof TOTAL_DETAIL.KEY_BROWSER ? KeyBrowserPanelInfo :
  Type extends typeof TOTAL_DETAIL.CONTAINER ? ContainerPanelInfo :
  Type extends typeof TOTAL_DETAIL.DISK_DETAIL ? HostDiskPanelInfo :
  Type extends typeof TOTAL_DETAIL.NETWORK_DETAIL ? HostNetworkPanelInfo :
  Type extends typeof TOTAL_DETAIL.PROCESS_DETAIL ? HostProcessPanelInfo :
  Type extends typeof TOTAL_DETAIL.HOST ? HostPanelInfo :
  Type extends typeof TOTAL_DETAIL.NETWORK_DEVICE ? NetworkDevicePanelInfo :
  Type extends typeof TOTAL_DETAIL.NETWORK_OBJECT ? NetworkObjectPanelInfo :
  Type extends typeof TOTAL_DETAIL.SCRIPT_DETAIL ? ScriptManagerHistoryPanelInfo :
  Type extends (typeof TOTAL_DETAIL)[keyof KuberDetail] ? KuberDetailPanelInfo :
  Type extends (typeof TOTAL_DETAIL)[keyof ServiceDetail] ? ServiceDetailPanelInfo :
  Type extends (typeof TOTAL_DETAIL)[keyof ToolDetail] ? QueryManagerDetailPanelInfo :
  Type extends typeof TOTAL_DETAIL.AGENT ? ConfigAgentPanelInfo :
  Type extends typeof TOTAL_DETAIL.EXECUTION ? ConfigExecutionPanelInfo :
  Type extends typeof TOTAL_DETAIL.USAGE_TREND ? UsageTrendPanelInfo :
  Type extends (typeof TOTAL_DETAIL)[keyof CloudDetail] ? CloudAwsPanelDetail :
  Type extends typeof TOTAL_DETAIL.CUSTOM ? CustomPanelInfo :
  never;
/* eslint-enable */

export type AddTabPanelInfo = PanelInfo<DetailType>;

// value값으로 탭 구분.
export type SlideTabPanelInfo = AddTabPanelInfo & {
  value: number;
  date: string;
};

type CustomSlideTabPanelInfo<T extends {} = {}> = {
  type: typeof TOTAL_DETAIL.CUSTOM;
  props: T;
};

const detailTypeCheckList: string[] = [
  'Node',
  'Namespace',
  'Pod',
  'Deployment',
  'DaemonSet',
  'StatefulSet',
  'ReplicaSet',
  'Service',
];
export const isDetailPage = (type: string): boolean => detailTypeCheckList.includes(type);

export const convertK8sTabPanelType = (str: string): KuberDetail[keyof KuberDetail] => {
  const type: Record<string, KuberDetail[keyof KuberDetail]> = {
    nodelist: TOTAL_DETAIL.NODE_LIST,
    namespacelist: TOTAL_DETAIL.NAMESPACE_LIST,
    podlist: TOTAL_DETAIL.POD_LIST,
    serviceobjectlist: TOTAL_DETAIL.SERVICE_OBJECT_LIST,
    ingresslist: TOTAL_DETAIL.INGRESS_LIST,
    persistentvolumeclaimlist: TOTAL_DETAIL.PERSISTENT_VOLUMES_CLAIMS_LIST,
    persistentvolumelist: TOTAL_DETAIL.PERSISTENT_VOLUMES_LIST,
    storageclasslist: TOTAL_DETAIL.STORAGE_CLASSES_LIST,
    replicaset: TOTAL_DETAIL.REPLICA_SET,
    daemonset: TOTAL_DETAIL.DAEMON_SET,
    statefulset: TOTAL_DETAIL.STATEFUL_SET,
    deployment: TOTAL_DETAIL.DEPLOYMENT,
    job: TOTAL_DETAIL.JOB,
    node: TOTAL_DETAIL.NODE,
    namespace: TOTAL_DETAIL.NAMESPACE,
    pod: TOTAL_DETAIL.POD,
    ingress: TOTAL_DETAIL.INGRESS,
    service: TOTAL_DETAIL.SERVICE,
    cronjob: TOTAL_DETAIL.CRON_JOB,
    rolesbindings: TOTAL_DETAIL.ROLES_BINDINGS,
    serviceaccounts: TOTAL_DETAIL.SERVICE_ACCOUNTS,
    roles: TOTAL_DETAIL.ROLES,
    configmaps: TOTAL_DETAIL.CONFIG_MAPS,
    persistentvolumesclaims: TOTAL_DETAIL.PERSISTENT_VOLUMES_CLAIMS,
    storageclasses: TOTAL_DETAIL.STORAGE_CLASSES,
    clusterroles: TOTAL_DETAIL.CLUSTER_ROLES,
    clusterrolesbindings: TOTAL_DETAIL.CLUSTER_ROLES_BINDINGS,
    persistentvolumes: TOTAL_DETAIL.PERSISTENT_VOLUMES,
  };

  return type[getDetailKey(str)] ?? TOTAL_DETAIL.NODE;
};

export const DetailTypeToPermissionKey: Partial<Record<DetailType, RolePermissionKeyType>> = {
  [TOTAL_DETAIL.NODE_LIST]: ROLE_PERMISSION_KEY.MONITORING.MONITORING_KUBERNETES_NODE,
  [TOTAL_DETAIL.NAMESPACE_LIST]: ROLE_PERMISSION_KEY.MONITORING.MONITORING_KUBERNETES_NAMESPACE,
  [TOTAL_DETAIL.POD_LIST]: ROLE_PERMISSION_KEY.MONITORING.MONITORING_KUBERNETES_POD,
  [TOTAL_DETAIL.SERVICE_OBJECT_LIST]: ROLE_PERMISSION_KEY.MONITORING.MONITORING_KUBERNETES_NETWORK,
  [TOTAL_DETAIL.INGRESS_LIST]: ROLE_PERMISSION_KEY.MONITORING.MONITORING_KUBERNETES_NETWORK,
  [TOTAL_DETAIL.PERSISTENT_VOLUMES_CLAIMS_LIST]:
    ROLE_PERMISSION_KEY.MONITORING.MONITORING_KUBERNETES_CONFIG_STORAGE,
  [TOTAL_DETAIL.PERSISTENT_VOLUMES_LIST]:
    ROLE_PERMISSION_KEY.MONITORING.MONITORING_KUBERNETES_CONFIG_STORAGE,
  [TOTAL_DETAIL.STORAGE_CLASSES_LIST]:
    ROLE_PERMISSION_KEY.MONITORING.MONITORING_KUBERNETES_CONFIG_STORAGE,
  [TOTAL_DETAIL.NAMESPACE]: ROLE_PERMISSION_KEY.MONITORING.MONITORING_KUBERNETES_NAMESPACE,
  [TOTAL_DETAIL.POD]: ROLE_PERMISSION_KEY.MONITORING.MONITORING_KUBERNETES_POD,
  [TOTAL_DETAIL.NODE]: ROLE_PERMISSION_KEY.MONITORING.MONITORING_KUBERNETES_NODE,
  [TOTAL_DETAIL.CRON_JOB]: ROLE_PERMISSION_KEY.MONITORING.MONITORING_KUBERNETES_WORKLOAD,
  [TOTAL_DETAIL.OWNER_REFERENCES]: ROLE_PERMISSION_KEY.MONITORING.MONITORING_KUBERNETES_WORKLOAD,
  [TOTAL_DETAIL.DEPLOYMENT]: ROLE_PERMISSION_KEY.MONITORING.MONITORING_KUBERNETES_WORKLOAD,
  [TOTAL_DETAIL.STATEFUL_SET]: ROLE_PERMISSION_KEY.MONITORING.MONITORING_KUBERNETES_WORKLOAD,
  [TOTAL_DETAIL.DAEMON_SET]: ROLE_PERMISSION_KEY.MONITORING.MONITORING_KUBERNETES_WORKLOAD,
  [TOTAL_DETAIL.JOB]: ROLE_PERMISSION_KEY.MONITORING.MONITORING_KUBERNETES_WORKLOAD,
  [TOTAL_DETAIL.REPLICA_SET]: ROLE_PERMISSION_KEY.MONITORING.MONITORING_KUBERNETES_WORKLOAD,
  [TOTAL_DETAIL.SERVICE]: ROLE_PERMISSION_KEY.MONITORING.MONITORING_KUBERNETES_NETWORK,
  [TOTAL_DETAIL.ROLES_BINDINGS]:
    ROLE_PERMISSION_KEY.MONITORING.MONITORING_KUBERNETES_ACCESS_CONTROL,
  [TOTAL_DETAIL.SERVICE_ACCOUNTS]:
    ROLE_PERMISSION_KEY.MONITORING.MONITORING_KUBERNETES_ACCESS_CONTROL,
  [TOTAL_DETAIL.ROLES]: ROLE_PERMISSION_KEY.MONITORING.MONITORING_KUBERNETES_ACCESS_CONTROL,
  [TOTAL_DETAIL.INGRESS]: ROLE_PERMISSION_KEY.MONITORING.MONITORING_KUBERNETES_NETWORK,
  [TOTAL_DETAIL.CONFIG_MAPS]: ROLE_PERMISSION_KEY.MONITORING.MONITORING_KUBERNETES_CONFIG_STORAGE,
  [TOTAL_DETAIL.PERSISTENT_VOLUMES_CLAIMS]:
    ROLE_PERMISSION_KEY.MONITORING.MONITORING_KUBERNETES_CONFIG_STORAGE,
  [TOTAL_DETAIL.STORAGE_CLASSES]:
    ROLE_PERMISSION_KEY.MONITORING.MONITORING_KUBERNETES_CONFIG_STORAGE,
  [TOTAL_DETAIL.CLUSTER_ROLES]: ROLE_PERMISSION_KEY.MONITORING.MONITORING_KUBERNETES_ACCESS_CONTROL,
  [TOTAL_DETAIL.CLUSTER_ROLES_BINDINGS]:
    ROLE_PERMISSION_KEY.MONITORING.MONITORING_KUBERNETES_ACCESS_CONTROL,
  [TOTAL_DETAIL.PERSISTENT_VOLUMES]:
    ROLE_PERMISSION_KEY.MONITORING.MONITORING_KUBERNETES_CONFIG_STORAGE,
  [TOTAL_DETAIL.HOST]: ROLE_PERMISSION_KEY.MONITORING.MONITORING_INFRASTRUCTURE_HOST,
  [TOTAL_DETAIL.CONTAINER]: ROLE_PERMISSION_KEY.MONITORING.MONITORING_INFRASTRUCTURE_CONTAINER,
  [TOTAL_DETAIL.NETWORK_DEVICE]:
    ROLE_PERMISSION_KEY.MONITORING.MONITORING_INFRASTRUCTURE_NETWORK_DEVICE,
  [TOTAL_DETAIL.PROCESS_DETAIL]: ROLE_PERMISSION_KEY.MONITORING.MONITORING_INFRASTRUCTURE_HOST,
  [TOTAL_DETAIL.NETWORK_DETAIL]: ROLE_PERMISSION_KEY.MONITORING.MONITORING_INFRASTRUCTURE_HOST,
  [TOTAL_DETAIL.DISK_DETAIL]: ROLE_PERMISSION_KEY.MONITORING.MONITORING_INFRASTRUCTURE_HOST,
  [TOTAL_DETAIL.SCRIPT_DETAIL]: ROLE_PERMISSION_KEY.MONITORING.MONITORING_INFRASTRUCTURE_TOOL,
  [TOTAL_DETAIL.WAS]: ROLE_PERMISSION_KEY.MONITORING.MONITORING_APPLICATION_WAS,
  [TOTAL_DETAIL.TRANSACTION]: ROLE_PERMISSION_KEY.MONITORING.MONITORING_APPLICATION_TRANSACTION,
  [TOTAL_DETAIL.ACTIVE_TRANSACTION]:
    ROLE_PERMISSION_KEY.MONITORING.MONITORING_APPLICATION_TRANSACTION,
  [TOTAL_DETAIL.PAGE_PERFORMANCE_MONITORING]:
    ROLE_PERMISSION_KEY.MONITORING.MONITORING_APPLICATION_PAGE_PERFORMANCE_MONITORING,
  [TOTAL_DETAIL.PAGE_PERFORMANCE_ANALYSIS]:
    ROLE_PERMISSION_KEY.MONITORING.MONITORING_APPLICATION_PAGE_PERFORMANCE_ANALYSIS,
  [TOTAL_DETAIL.URL_MONITORING]:
    ROLE_PERMISSION_KEY.MONITORING.MONITORING_APPLICATION_URL_MONITORING,
  [TOTAL_DETAIL.NETWORK_OBJECT]:
    ROLE_PERMISSION_KEY.MONITORING.MONITORING_INFRASTRUCTURE_NETWORK_PERFORMANCE_MONITORING,
  [TOTAL_DETAIL.BROWSER_ERROR_TRACKING]:
    ROLE_PERMISSION_KEY.MONITORING.MONITORING_APPLICATION_BROWSER_ERROR_TRACKING,
  [TOTAL_DETAIL.ALERT]: ROLE_PERMISSION_KEY.MONITORING.MONITORING_ALERT_LIST,
  [TOTAL_DETAIL.SERVICE_LIST]: ROLE_PERMISSION_KEY.MONITORING.MONITORING_BUSINESS_LIST,
  [TOTAL_DETAIL.INSTANCE]: ROLE_PERMISSION_KEY.MONITORING.MONITORING_DATABASE_INSTANCE,
  [TOTAL_DETAIL.CLUSTER]: ROLE_PERMISSION_KEY.MONITORING.MONITORING_DATABASE_INSTANCE,
  [TOTAL_DETAIL.SHARDED_CLUSTER]: ROLE_PERMISSION_KEY.MONITORING.MONITORING_DATABASE_INSTANCE,
  [TOTAL_DETAIL.SESSION]: {
    [VIEW_MODE.EXEMONE]: ROLE_PERMISSION_KEY.MONITORING.MONITORING_DATABASE_INSTANCE,
  },
  [TOTAL_DETAIL.SQL]: {
    [VIEW_MODE.EXEMONE]: ROLE_PERMISSION_KEY.MONITORING.MONITORING_DATABASE_INSTANCE,
  },
  [TOTAL_DETAIL.PARAMETER]: {
    [VIEW_MODE.EXEMONE]: ROLE_PERMISSION_KEY.MONITORING.MONITORING_DATABASE_INSTANCE,
  },
  [TOTAL_DETAIL.BROKER]: {
    [VIEW_MODE.EXEMONE]: ROLE_PERMISSION_KEY.MONITORING.MONITORING_DATABASE_INSTANCE,
  },
  [TOTAL_DETAIL.QUERY]: ROLE_PERMISSION_KEY.MONITORING.MONITORING_ANALYSIS_TOOL,
  [TOTAL_DETAIL.USAGE_TREND]: ROLE_PERMISSION_KEY.SETTING.SETTING_MANAGEMENT_DATA_RETENTION,
  [TOTAL_DETAIL.AGENT]: ROLE_PERMISSION_KEY.SETTING.SETTING_MANAGEMENT_AGENT, // NOTE: Agent 는 무조건 마스터 권한
  [TOTAL_DETAIL.EXECUTION]: ROLE_PERMISSION_KEY.SETTING.SETTING_MANAGEMENT_AGENT_EXECUTION,

  [TOTAL_DETAIL.AWS_EC2]: ROLE_PERMISSION_KEY.MONITORING.MONITORING_CLOUD_AWS,
  [TOTAL_DETAIL.AWS_LAMBDA]: ROLE_PERMISSION_KEY.MONITORING.MONITORING_CLOUD_AWS,
  [TOTAL_DETAIL.AWS_S3]: ROLE_PERMISSION_KEY.MONITORING.MONITORING_CLOUD_AWS,
  [TOTAL_DETAIL.AWS_DOCUMENTDB]: ROLE_PERMISSION_KEY.MONITORING.MONITORING_CLOUD_AWS,
  [TOTAL_DETAIL.AWS_DYNAMODB]: ROLE_PERMISSION_KEY.MONITORING.MONITORING_CLOUD_AWS,
  [TOTAL_DETAIL.AWS_ELASTICACHE]: ROLE_PERMISSION_KEY.MONITORING.MONITORING_CLOUD_AWS,
  [TOTAL_DETAIL.AWS_RDS]: ROLE_PERMISSION_KEY.MONITORING.MONITORING_CLOUD_AWS,
  [TOTAL_DETAIL.AWS_REDSHIFT]: ROLE_PERMISSION_KEY.MONITORING.MONITORING_CLOUD_AWS,
} as const;

export const CurrentDashboardDetailPermission: Record<string, RolePermissionKeyType> = {
  [BASE_MENU_VALUE.INFRASTRUCTURE]:
    ROLE_PERMISSION_KEY.MONITORING.MONITORING_INFRASTRUCTURE_DASHBOARD,
  [BASE_MENU_VALUE.KUBERNETES]: ROLE_PERMISSION_KEY.MONITORING.MONITORING_KUBERNETES_DASHBOARD,
  [BASE_MENU_VALUE.APPLICATION]: ROLE_PERMISSION_KEY.MONITORING.MONITORING_APPLICATION_DASHBOARD,
  [BASE_MENU_VALUE.DATABASE]: ROLE_PERMISSION_KEY.MONITORING.MONITORING_DATABASE_DASHBOARD,
  [BASE_MENU_VALUE.BUSINESS]: ROLE_PERMISSION_KEY.MONITORING.MONITORING_BUSINESS_DASHBOARD,
  [BASE_MENU_VALUE.LOGS]: ROLE_PERMISSION_KEY.MONITORING.MONITORING_LOGS_DASHBOARD,
  [BASE_MENU_VALUE.ALERT]: ROLE_PERMISSION_KEY.MONITORING.MONITORING_ALERT_DASHBOARD,
  [BASE_MENU_VALUE.ORACLE]: {
    [VIEW_MODE.MAXGAUGE]: ROLE_PERMISSION_KEY.MONITORING.MONITORING_ORACLE_DASHBOARD,
  },
  [BASE_MENU_VALUE.PG]: {
    [VIEW_MODE.MAXGAUGE]: ROLE_PERMISSION_KEY.MONITORING.MONITORING_POSTGRESQL_DASHBOARD,
  },
  [BASE_MENU_VALUE.MYSQL]: {
    [VIEW_MODE.MAXGAUGE]: ROLE_PERMISSION_KEY.MONITORING.MONITORING_MYSQL_DASHBOARD,
  },
  [BASE_MENU_VALUE.SQLSERVER]: {
    [VIEW_MODE.MAXGAUGE]: ROLE_PERMISSION_KEY.MONITORING.MONITORING_SQLSERVER_DASHBOARD,
  },
  [BASE_MENU_VALUE.REDIS]: {
    [VIEW_MODE.MAXGAUGE]: ROLE_PERMISSION_KEY.MONITORING.MONITORING_REDIS_DASHBOARD,
  },
  [BASE_MENU_VALUE.CUBRID]: {
    [VIEW_MODE.MAXGAUGE]: ROLE_PERMISSION_KEY.MONITORING.MONITORING_CUBRID_DASHBOARD,
  },
  [BASE_MENU_VALUE.MONGODB]: {
    [VIEW_MODE.MAXGAUGE]: ROLE_PERMISSION_KEY.MONITORING.MONITORING_MONGO_DASHBOARD,
  },
} as const;

const useDetailSlidePermission = () => {
  const route = useRoute();

  const { isPermissionDenied } = useRolePermission();

  const isDetailSlidePermissionDenied = ({
    rolePermissionKey,
    type,
  }: {
    rolePermissionKey?: RolePermissionKeyType;
    type?: DetailType;
  }) => {
    return (
      (route.query.rootMenu &&
        isPermissionDenied({
          type: 'detailSlide',
          rolePermissionKey: CurrentDashboardDetailPermission[`${route.query.rootMenu}`],
        })) ||
      isPermissionDenied({
        type: 'detailSlide',
        rolePermissionKey: rolePermissionKey || DetailTypeToPermissionKey[type],
      })
    );
  };

  return {
    isDetailSlidePermissionDenied,
  };
};

export const useSplitSlideDetail = () => {
  const isSplitOpened = ref(false);
  const isSplitDetailLoading = ref(false);

  const splitDetailComp = shallowRef();

  const slideSplitTabPanel = ref<(SlideTabPanelInfo | CustomSlideTabPanelInfo)[]>([]);
  const splitSelectedSlideTabPanel = ref<SlideTabPanelInfo | CustomSlideTabPanelInfo>();

  const initSplitTabPanel = () => {
    isSplitOpened.value = false;
    isSplitDetailLoading.value = false;
    splitDetailComp.value = undefined;
    splitSelectedSlideTabPanel.value = undefined;
    slideSplitTabPanel.value = [];
  };

  const setSplitOpened = (value: boolean) => {
    isSplitOpened.value = value;
    if (!value) {
      initSplitTabPanel();
    }
  };

  const { isDetailSlidePermissionDenied } = useDetailSlidePermission();

  const setSelectedSlideTabPanel = (tabPanelInfo: SlideTabPanelInfo | CustomSlideTabPanelInfo) => {
    splitSelectedSlideTabPanel.value = tabPanelInfo;
    slideSplitTabPanel.value = [splitSelectedSlideTabPanel.value];
  };

  const addSplitTabPanel = <T extends {} = {}>(
    tabPanelInfo:
      | AddTabPanelInfo
      | {
          type: typeof TOTAL_DETAIL.CUSTOM;
          comp: Component | string;
          props?: T;
        },
  ) => {
    const { type } = tabPanelInfo;

    if (type === TOTAL_DETAIL.CUSTOM) {
      const { comp, props } = tabPanelInfo;
      setSelectedSlideTabPanel({
        type,
        props,
      } as CustomSlideTabPanelInfo);
      splitDetailComp.value = comp;
      setSplitOpened(true);
      return;
    }

    const { rolePermissionKey } = tabPanelInfo as AddTabPanelInfo;

    if (isDetailSlidePermissionDenied({ rolePermissionKey, type })) {
      return;
    }

    setSelectedSlideTabPanel({
      ...tabPanelInfo,
      value: Date.now(),
      date: dayjs().format('YYYY-MM-DD HH:mm:ss'),
      state: {
        ...(tabPanelInfo.state || {}),
      },
    } as SlideTabPanelInfo);
    splitDetailComp.value = DETAIL_COMPONENTS[type].component;
    setSplitOpened(true);
  };

  const setIsSplitDetailLoading = (isLoading: boolean) => {
    isSplitDetailLoading.value = isLoading;
  };

  const selectedSplitTabComputed = (initialTab: string = '') =>
    computed({
      get: () =>
        (splitSelectedSlideTabPanel.value as SlideTabPanelInfo)?.state?.selectedTab ?? initialTab,
      set: (value) => {
        const typedSplitSelectedSlideTabPanel =
          splitSelectedSlideTabPanel.value as SlideTabPanelInfo;
        if (value === typedSplitSelectedSlideTabPanel?.state?.selectedTab) {
          return;
        }

        if (splitSelectedSlideTabPanel.value) {
          typedSplitSelectedSlideTabPanel.state = {
            ...typedSplitSelectedSlideTabPanel.state,
            selectedTab: value,
          };
        }
      },
    });

  return {
    isSplitOpened,
    splitDetailComp,
    slideSplitTabPanel,
    splitSelectedSlideTabPanel,
    isSplitDetailLoading,
    selectedSplitTabComputed,

    setSplitOpened,
    addSplitTabPanel,
    initSplitTabPanel,
    setIsSplitDetailLoading,
  };
};

export const useSlideDetail = () => {
  const isOpened = ref(false);
  const isDetailLoading = ref(false);

  const detailComp = shallowRef();

  const slideTabPanel = ref<SlideTabPanelInfo[]>([]);
  const selectedSlideTabPanel = ref<SlideTabPanelInfo>();
  const slideDetailHistory = ref<SlideTabPanelInfo[]>([]); // history용

  const {
    isSplitOpened,
    splitDetailComp,
    slideSplitTabPanel,
    splitSelectedSlideTabPanel,
    isSplitDetailLoading,
    selectedSplitTabComputed,

    setSplitOpened,
    addSplitTabPanel,
    initSplitTabPanel,
    setIsSplitDetailLoading,
  } = useSplitSlideDetail();

  const isExcess = ref<boolean>(false);

  const onClickExcess = () => {
    isExcess.value = false;
  };

  const { isDetailSlidePermissionDenied } = useDetailSlidePermission();

  const setDetail = (type: string) => {
    detailComp.value = DETAIL_COMPONENTS[type].component;
  };

  const initTabPanel = () => {
    slideTabPanel.value = [];
    slideDetailHistory.value = [];
    selectedSlideTabPanel.value = undefined;
    initSplitTabPanel();
  };

  const setSelectedSlideTabPanel = (tabPanelInfo: SlideTabPanelInfo) => {
    initSplitTabPanel();

    const isClickFromMainView =
      tabPanelInfo.init && tabPanelInfo.type === selectedSlideTabPanel.value?.type;

    selectedSlideTabPanel.value = {
      ...tabPanelInfo,
      state: isClickFromMainView ? selectedSlideTabPanel.value?.state ?? {} : tabPanelInfo.state,
    };

    setDetail(tabPanelInfo.type);
  };

  const addSlideDetailHistory = (tabPanelInfo: SlideTabPanelInfo) => {
    slideDetailHistory.value.push(tabPanelInfo);
    setSelectedSlideTabPanel(tabPanelInfo);
  };

  const hasSamePanel = (tabPanelInfo: SlideTabPanelInfo) => {
    const compareInfo = omit(tabPanelInfo, ['date', 'value', 'state']);
    return slideTabPanel.value.findIndex((item) => {
      const itemCompareInfo = omit(item, ['date', 'value', 'state']);
      return isEqual(compareInfo, itemCompareInfo);
    });
  };

  const deleteSlideTabPanel = (tabPanelInfo: SlideTabPanelInfo) => {
    const samePanelIndex = hasSamePanel(tabPanelInfo);
    if (samePanelIndex !== -1) {
      slideTabPanel.value.splice(samePanelIndex, 1);
    }
  };

  const addSlideTabPanel = (tabPanelInfo: SlideTabPanelInfo) => {
    if (hasSamePanel(tabPanelInfo) !== -1) {
      deleteSlideTabPanel(tabPanelInfo);
    }
    slideTabPanel.value.splice(1, 0, tabPanelInfo);
  };

  const deleteLastSlideDetailHistory = () => {
    return slideDetailHistory.value.pop();
  };

  // 패널의 고유 ID를 생성하는 함수
  const getUniqueId = (panel: AddTabPanelInfo): string => {
    switch (panel.type) {
      case TOTAL_DETAIL.INSTANCE:
      case TOTAL_DETAIL.SHARDED_CLUSTER:
        return panel.instance?.instanceId || '';
      case TOTAL_DETAIL.SERVICE_LIST:
        return panel.addition?.serviceId || '';
      case TOTAL_DETAIL.WAS:
        return panel.was?.wasId || '';
      case TOTAL_DETAIL.ALERT:
        return `${panel.addition?.ruleId}-${panel.addition?.ruleName}` || '';
      case TOTAL_DETAIL.TRANSACTION:
        return panel.transaction?.tid || '';
      case TOTAL_DETAIL.ACTIVE_TRANSACTION:
        return panel.addition?.tid || '';
      case TOTAL_DETAIL.PAGE_PERFORMANCE_MONITORING:
        return panel.application?.applicationId || '';
      case TOTAL_DETAIL.PAGE_PERFORMANCE_ANALYSIS:
        return panel.page?.sessionId || '';
      case TOTAL_DETAIL.URL_MONITORING:
        return panel.addition?.url || ''; // url모니터링체크
      case TOTAL_DETAIL.BROWSER_ERROR_TRACKING:
        return (
          `${panel.addition?.applicationId}-${panel.addition?.sessionId}-${panel.addition?.collectTime}-${panel.addition?.pageAccessTime}-${panel.addition?.error}` ||
          ''
        );
      case TOTAL_DETAIL.AGENT:
        return panel.addition?.agentId || '';
      case TOTAL_DETAIL.EXECUTION:
        return panel.addition?.executionId || '';
      case TOTAL_DETAIL.SESSION:
        return `${panel.session?.dbType}-${panel.name}` || '';
      case TOTAL_DETAIL.PARAMETER:
        return `${panel.addition?.dbType}-${panel.addition?.instanceId}-${panel.name}` || '';
      case TOTAL_DETAIL.CONTAINER:
        return panel.container || '';
      case TOTAL_DETAIL.DISK_DETAIL:
      case TOTAL_DETAIL.NETWORK_DETAIL:
      case TOTAL_DETAIL.HOST:
        return panel.host || '';
      case TOTAL_DETAIL.PROCESS_DETAIL:
        return `${panel.host}-${panel.pid}` || '';
      // case TOTAL_DETAIL.SQL:
      //   return panel.name || '';
      case TOTAL_DETAIL.NETWORK_DEVICE:
        return panel.addition?.deviceId || '';
      case TOTAL_DETAIL.AWS_EC2:
      case TOTAL_DETAIL.AWS_LAMBDA:
      case TOTAL_DETAIL.AWS_DOCUMENTDB:
      case TOTAL_DETAIL.AWS_S3:
      case TOTAL_DETAIL.AWS_DYNAMODB:
      case TOTAL_DETAIL.AWS_ELASTICACHE:
      case TOTAL_DETAIL.AWS_RDS:
      case TOTAL_DETAIL.AWS_REDSHIFT:
        return panel.addition?.instanceId || '';
      case TOTAL_DETAIL.NETWORK_OBJECT:
        return panel.addition?.networkObjectId || '';
      case TOTAL_DETAIL.SCRIPT_DETAIL:
        return panel.addition?.executionId || '';
      case TOTAL_DETAIL.QUERY:
        return panel.addition?.historyId || '';
      case TOTAL_DETAIL.NODE_LIST:
      case TOTAL_DETAIL.NAMESPACE_LIST:
      case TOTAL_DETAIL.POD_LIST:
      case TOTAL_DETAIL.SERVICE_OBJECT_LIST:
      case TOTAL_DETAIL.INGRESS_LIST:
      case TOTAL_DETAIL.PERSISTENT_VOLUMES_CLAIMS_LIST:
      case TOTAL_DETAIL.PERSISTENT_VOLUMES_LIST:
      case TOTAL_DETAIL.STORAGE_CLASSES_LIST:
      case TOTAL_DETAIL.NAMESPACE:
      case TOTAL_DETAIL.POD:
      case TOTAL_DETAIL.NODE:
      case TOTAL_DETAIL.CRON_JOB:
      case TOTAL_DETAIL.OWNER_REFERENCES:
      case TOTAL_DETAIL.DEPLOYMENT:
      case TOTAL_DETAIL.STATEFUL_SET:
      case TOTAL_DETAIL.DAEMON_SET:
      case TOTAL_DETAIL.JOB:
      case TOTAL_DETAIL.REPLICA_SET:
      case TOTAL_DETAIL.SERVICE:
      case TOTAL_DETAIL.ROLES_BINDINGS:
      case TOTAL_DETAIL.SERVICE_ACCOUNTS:
      case TOTAL_DETAIL.ROLES:
      case TOTAL_DETAIL.INGRESS:
      case TOTAL_DETAIL.CONFIG_MAPS:
      case TOTAL_DETAIL.PERSISTENT_VOLUMES_CLAIMS:
      case TOTAL_DETAIL.STORAGE_CLASSES:
      case TOTAL_DETAIL.CLUSTER_ROLES:
      case TOTAL_DETAIL.CLUSTER_ROLES_BINDINGS:
      case TOTAL_DETAIL.PERSISTENT_VOLUMES:
        return panel.addition?.uid || '';
      default:
        return '';
    }
  };

  const setOpened = (value: boolean) => {
    isOpened.value = value;
    if (!value) {
      initTabPanel();
    }
  };

  const addTabPanel = (
    tabPanelInfo: AddTabPanelInfo,
    currentState?: SlideTabPanelInfo['state'],
  ) => {
    const { type, init, rolePermissionKey } = tabPanelInfo;

    if (isDetailSlidePermissionDenied({ rolePermissionKey, type })) {
      return;
    }

    if (type !== TOTAL_DETAIL.SQL) {
      const newPanelId = getUniqueId(tabPanelInfo);

      if (
        selectedSlideTabPanel.value &&
        selectedSlideTabPanel.value.type === type &&
        getUniqueId(selectedSlideTabPanel.value) === newPanelId &&
        isOpened.value &&
        currentState?.keepOpen !== true
      ) {
        setOpened(false);
        return;
      }
    }

    const keepState = selectedSlideTabPanel.value?.type === type && init;
    const beforeState = {
      ...(selectedSlideTabPanel.value?.state ?? {}),
    };

    if (init) {
      initTabPanel();
    } else if (currentState) {
      selectedSlideTabPanel.value!.state = currentState;
    }

    if (slideTabPanel.value.length > 30) {
      isExcess.value = true;
      return;
    }

    const date = dayjs().format('YYYY-MM-DD HH:mm:ss');
    const dateToValue = Date.now();

    const newTab = {
      ...tabPanelInfo,
      value: dateToValue,
      date,
      state: {
        ...tabPanelInfo.state,
        ...(keepState ? beforeState : {}),
      },
    };

    addSlideTabPanel(newTab);
    addSlideDetailHistory(newTab);
    setOpened(true);
  };

  const loadingList: string[] = [];

  const setIsDetailLoading = (
    isLoading:
      | boolean
      | {
          type: string;
          isLoading: boolean;
        },
  ) => {
    if (typeof isLoading === 'object' && 'type' in isLoading && 'isLoading' in isLoading) {
      if (isLoading.isLoading) {
        loadingList.push(isLoading.type);
      } else {
        loadingList.splice(loadingList.indexOf(isLoading.type), 1);
      }
      if (loadingList.length === 0) {
        isDetailLoading.value = false;
      } else {
        isDetailLoading.value = true;
      }
    } else if (typeof isLoading === 'boolean') {
      isDetailLoading.value = isLoading;
      if (isLoading === false) {
        loadingList.splice(0, loadingList.length);
      }
    }
  };

  const selectedTabComputed = (initialTab: string = '') =>
    computed({
      get: () => selectedSlideTabPanel.value?.state?.selectedTab ?? initialTab,
      set: (value) => {
        if (value === selectedSlideTabPanel.value?.state?.selectedTab) {
          return;
        }

        if (selectedSlideTabPanel.value) {
          initSplitTabPanel();

          selectedSlideTabPanel.value.state = {
            ...selectedSlideTabPanel.value.state,
            selectedTab: value,
          };

          const findSlideDetailHistory = slideDetailHistory.value.find(
            (h) => h.type === selectedSlideTabPanel?.value?.type,
          );
          if (findSlideDetailHistory) {
            findSlideDetailHistory.state = cloneDeep(selectedSlideTabPanel?.value?.state);
          }
        } else {
          // History를 사용하지 않고, Detail View만 사용하는 경우
          selectedSlideTabPanel.value = {
            type: TOTAL_DETAIL.CUSTOM,
            name: '',
            value: 0,
            date: '',
            state: {
              selectedTab: value,
            },
          };
        }
      },
    });

  return {
    isOpened,
    isDetailLoading,
    detailComp,
    slideTabPanel,
    selectedSlideTabPanel,
    slideDetailHistory,
    selectedTabComputed,
    setDetail,
    setSelectedSlideTabPanel,
    initTabPanel,
    addSlideDetailHistory,
    deleteLastSlideDetailHistory,
    addTabPanel,
    setIsDetailLoading,
    setOpened,
    deleteSlideTabPanel,
    isExcess,
    onClickExcess,

    isSplitOpened,
    splitDetailComp,
    slideSplitTabPanel,
    splitSelectedSlideTabPanel,
    isSplitDetailLoading,
    selectedSplitTabComputed,
    setSplitOpened,
    addSplitTabPanel,
    initSplitTabPanel,
    setIsSplitDetailLoading,
  };
};
