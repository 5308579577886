<script lang="ts" setup>
import DetailInformation from '@/common/components/molecules/DetailInformation.vue';
import LabelItem from '@/common/components/molecules/labelItem/LabelItem.vue';
import LabelItemGroup from '@/common/components/templates/labelItemGroup/LabelItemGroup.vue';
import AlertRuleCard from '@/alert/components/alertRuleCard/AlertRuleCard.vue';
import StatusTag from '@/common/components/molecules/statusTag/StatusTag.vue';
import { kebabCase } from 'lodash-es';
import { setup } from '@/alert/components/alertDetail/alertInformation.setup';
import {
  AlertDetailEmit,
  AlertDetailTabProps,
} from '@/alert/components/alertDetail/alertDetail.types';
import AlertInformationNotification from '@/alert/components/alertDetail/AlertInformationNotification.vue';
import { ALERT_TYPE_VALUE, CHECK_BY_TARGET } from '@/alert/utils/define';
import BaseGrid from '@/common/components/molecules/grid/BaseGrid.vue';
import { baseGridOption } from '@/common/utils';

const props = defineProps<AlertDetailTabProps>();
const emit = defineEmits<AlertDetailEmit>();

const {
  ruleInfo,
  commonRuleData,
  conditionsData,
  conditionsDataByEvent,
  notificationData,
  userAlertType,
  eventType,
  eventTypeTargets,
  getListColumnTitle,
  t,
} = setup(props, emit);
</script>

<template>
  <section class="alert-detail-information">
    <detail-information
      v-if="ruleInfo.type === 'user'"
      :title="t('WORD.RULE_DATA')"
      class="alert-detail-information__select-data"
    >
      <template #content>
        <div class="alert-detail-information__rule-common">
          <label-item-group>
            <label-item
              :label="commonRuleData.type.label"
              class="type"
            >
              {{ commonRuleData.type.value }}
            </label-item>
            <label-item
              :label="commonRuleData.ruleCriteria.label"
              class="type"
            >
              <status-tag
                v-if="commonRuleData.ruleCriteria.value"
                type="inactive"
              >
                {{ commonRuleData.ruleCriteria.value }}
              </status-tag>
            </label-item>
          </label-item-group>

          <label-item-group>
            <template
              v-for="(item, key) in commonRuleData"
              :key="key"
            >
              <label-item
                v-if="!['type', 'ruleCriteria'].includes(key)"
                :label="item.label"
                :class="kebabCase(key)"
              >
                <template v-if="item.renderer === 'list'">
                  <span
                    v-for="(val, idx) in item.value"
                    :key="`list${idx}`"
                    :title="getListColumnTitle(item.value)"
                    class="label-item__list"
                  >
                    {{ val }}
                  </span>
                </template>
                <template v-else>
                  {{ item.value }}
                </template>
              </label-item>
            </template>
          </label-item-group>
        </div>

        <div
          v-if="userAlertType === ALERT_TYPE_VALUE.EVENT"
          class="alert-detail-information__event-type-targets"
        >
          <label-item-group>
            <label-item
              :label="eventType?.label ?? ''"
              class="type"
            >
              {{ eventType?.value }}
            </label-item>
          </label-item-group>
          <alert-rule-card
            :user-alert-type="userAlertType"
            :rule-info="eventTypeTargets"
            :rule-criteria="commonRuleData.ruleCriteria?.value ?? ''"
          />
        </div>

        <alert-rule-card
          v-if="userAlertType === ALERT_TYPE_VALUE.EVENT && eventType?.key === 'Logging'"
          :user-alert-type="userAlertType"
          :rule-info="conditionsDataByEvent"
        >
          <template #path>
            <base-grid
              :columns="conditionsDataByEvent.path?.value.columns || []"
              :rows="conditionsDataByEvent.path?.value.rows || []"
              :option="{
                ...baseGridOption,
                useSelection: {
                  use: false,
                },
              }"
            />
          </template>
        </alert-rule-card>

        <alert-rule-card
          v-for="(rule, idx) in conditionsData"
          :key="`rule${idx}`"
          :user-alert-type="userAlertType"
          :rule-info="rule"
          :rule-criteria="commonRuleData.ruleCriteria?.value ?? ''"
        />
      </template>
    </detail-information>

    <template
      v-for="(notiData, notiIdx) in notificationData"
      :key="`notification${notiIdx}`"
    >
      <alert-information-notification
        :rule-criteria="commonRuleData.ruleCriteria.value || CHECK_BY_TARGET"
        :data="notiData"
      />
    </template>
  </section>
</template>

<style lang="scss">
.alert-detail-information {
  padding-bottom: 10px;
  .label-item {
    &__label {
      color: var(--alert-rule-item-label-color);
    }
    &__body {
      color: var(--alert-rule-item-value-color);
    }
  }
  .status-tag {
    min-width: 50px;
    padding: 2px 4px 1px;
  }
  .alert-rule-card {
    padding: 0 12px;
    border: 1px solid var(--alert-rule-card-border-color);
    border-radius: 2px;
    background: var(--alert-rule-card-bg-color);

    &:not(:last-child) {
      margin-bottom: 8px;
    }
  }
  .alert-rule-row {
    display: flex;
    align-items: center;
    padding: 16px 0;
    &:not(:last-child) {
      border-bottom: 1px dashed var(--alert-rule-card-dashed-border-color);
    }

    &__label {
      min-width: 96px;
      margin-right: 8px;
      color: var(--alert-rule-item-label-color);
    }
    &__value {
      position: relative;
      min-width: 100px;
      padding-right: 40px;
      margin-right: 40px;
      color: var(--alert-rule-item-value-color);

      &:not(:last-child)::after {
        position: absolute;
        top: 50%;
        right: 0;
        width: 1px;
        height: 12px;
        background-color: var(--alert-rule-card-border-color);
        transform: translateY(-50%);
        content: '';
      }

      .dim {
        color: var(--alert-rule-item-label-color);
      }
    }
  }
  &__select-data {
    margin-bottom: 10px;
    .detail-information__content {
      padding: 16px 10px 12px;
    }
    .label-item-group {
      padding: 0 4px;
    }
    .label-item {
      margin-bottom: 12px;
      &::after {
        background-color: var(--alert-rule-card-border-color);
      }
    }
  }
  &__rule-common {
    padding-top: 4px;
    .check-hold-time::after {
      content: none;
    }
    .label-item__list:not(:last-child) {
      padding-right: 8px;
    }
    .time {
      .label-item__body {
        display: inline-flex;
      }
      .label-item__list:first-child {
        width: auto !important;
        max-width: 160px;
        @include shortening();
      }
    }
  }
  &__event-type-targets {
    margin-bottom: 8px;
    .alert-rule-row__label {
      min-width: 96px !important;
    }
  }
  /* stylelint-disable */
  .base-grid .ev-grid.table .row:hover {
    background-color: transparent !important;
  }
  /* stylelint-enable */
  &__notification {
    &:not(:last-child) {
      margin-bottom: 10px;
    }
  }
}
.slide-out--expand {
  .alert-detail-information__rule-common .check-hold-time::after {
    content: '';
  }
}
</style>
